import {
  Asset,
  BucketDefinition,
  EditorConfiguration,
  EditorValidationErrors,
  LineAsset,
  LineDefinition,
  LineTestDefinition,
  LocaleAsset,
  LocaleDefinition,
  OptionListDefinition,
  PriorEnquiryDefinition,
  QuestionAsset,
  QuestionDefinition,
  TableDefinition, WrapUpLine,
} from '../../../dtos/editor.dto';
import { PriorEnquiryChannel } from '../../enquiry/enquiry.service';
import { addMocks, APIRequest } from '../../types';
import Mocks from './mocks';
import { EditorAPI, TypeAheadBucketValueReplacements } from './types';
import { axiosAuthClient } from '../../auth/axiosAuthClient';
import { getFile } from '../../../utils/getFile';

const defaultUrl = '/apiEde';

export const getEditorAPI = (baseUrl = defaultUrl) => {
  const api: EditorAPI = {
    getBranchInfo: new APIRequest(
      (branchName) => axiosAuthClient.get<string[]>(`${baseUrl}/branches/${encodeURIComponent(branchName)}`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+', method: 'get' },
    ),
    getLinesList: new APIRequest(
      (branchId) => axiosAuthClient.get<LineAsset[]>(`${baseUrl}/branches/${branchId}/tree/lines`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/lines', method: 'get' },
    ),
    getBucketsList: new APIRequest(
      (branchId) => axiosAuthClient.get<Asset[]>(`${baseUrl}/branches/${branchId}/tree/buckets`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/buckets', method: 'get' },
    ),
    getOptionListList: new APIRequest(
      (branchId) => axiosAuthClient.get<Asset[]>(`${baseUrl}/branches/${branchId}/tree/option-lists`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/option-lists', method: 'get' },
    ),
    getQuestionsList: new APIRequest(
      (branchId) => axiosAuthClient.get<QuestionAsset[]>(`${baseUrl}/branches/${branchId}/tree/questions`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/questions', method: 'get' },
    ),
    getLocalesList: new APIRequest(
      (branchId) => axiosAuthClient.get<LocaleAsset[]>(`${baseUrl}/branches/${branchId}/tree/locales`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/locales', method: 'get' },
    ),
    getPriorEnquiryChannelList: new APIRequest(
      (branchId) => axiosAuthClient.get<Asset[]>(`${baseUrl}/branches/${branchId}/tree/channels`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/prior-enquiry-channels', method: 'get' },
    ),
    getBucket: new APIRequest(
      ({ branchName, bucketName }) => axiosAuthClient.get<BucketDefinition>(`${baseUrl}/branches/${branchName}/rule/buckets/${bucketName}`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/buckets\\/[a-zA-Z0-9\\-]+', method: 'get' },
    ),
    updateBucket: new APIRequest(
      ({
        branchName,
        bucketName,
        bucket,
        commitMessage,
      }) => axiosAuthClient.put<BucketDefinition>(`${baseUrl}/branches/${branchName}/rule/buckets/${bucketName}`, { commitMessage, asset: bucket }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/buckets\\/[a-zA-Z0-9\\-]+', method: 'put' },
    ),
    createBucket: new APIRequest(
      ({ branchName, bucket, commitMessage }) => axiosAuthClient.post<BucketDefinition>(`${baseUrl}/branches/${branchName}/rule/buckets`, { commitMessage, asset: bucket }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/buckets', method: 'post' },
    ),
    deleteBucket: new APIRequest(
      ({ branchName, bucketName }) => axiosAuthClient.delete<void>(`${baseUrl}/branches/${branchName}/rule/buckets/${bucketName}`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/buckets\\/[a-zA-Z0-9\\-]+', method: 'delete' },
    ),
    getLocale: new APIRequest(
      ({ branchName, localeName }) => axiosAuthClient.get<LocaleDefinition>(`${baseUrl}/branches/${branchName}/rule/locales/${localeName}`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/locales\\/[a-zA-Z0-9\\-]+', method: 'get' },
    ),
    updateLocale: new APIRequest(
      ({
        branchName, localeName, locale, commitMessage,
      }) => axiosAuthClient.put<LocaleDefinition>(`${baseUrl}/branches/${branchName}/rule/locales/${localeName}`, { commitMessage, asset: locale }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/locales\\/[a-zA-Z0-9\\-]+', method: 'put' },
    ),
    createLocale: new APIRequest(
      ({ branchName, locale, commitMessage }) => axiosAuthClient.post<LocaleDefinition>(`${baseUrl}/branches/${branchName}/rule/locales`, { commitMessage, asset: locale }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/locales', method: 'post' },
    ),
    deleteLocale: new APIRequest(
      ({ branchName, localeName }) => axiosAuthClient.delete<void>(`${baseUrl}/branches/${branchName}/locales/${localeName}`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/locales\\/[a-zA-Z0-9\\-]+', method: 'delete' },
    ),
    getQuestion: new APIRequest(
      ({ branchName, questionName }) => axiosAuthClient.get<QuestionDefinition>(`${baseUrl}/branches/${branchName}/rule/questions/${questionName}`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/questions\\/[a-zA-Z0-9\\-]+', method: 'get' },
    ),
    searchQuestions: new APIRequest(
      ({ branchName, questionNames }) => axiosAuthClient.post<QuestionDefinition[]>(`${baseUrl}/branches/${branchName}/rule/questions/searches`, { names: questionNames }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/questions\\/searches', method: 'post' },
    ),
    updateQuestion: new APIRequest(
      ({
        branchName, questionName, question, commitMessage,
      }) => axiosAuthClient.put<QuestionDefinition>(`${baseUrl}/branches/${branchName}/rule/questions/${questionName}`, { commitMessage, asset: question }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/questions\\/[a-zA-Z0-9\\-]+', method: 'put' },
    ),
    createQuestion: new APIRequest(
      ({ branchName, question, commitMessage }) => axiosAuthClient.post<QuestionDefinition>(`${baseUrl}/branches/${branchName}/rule/questions`, { commitMessage, asset: question }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/questions', method: 'post' },
    ),
    deleteQuestion: new APIRequest(
      ({ branchName, questionName }) => axiosAuthClient.delete<void>(`${baseUrl}/branches/${branchName}/rule/questions/${questionName}`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/questions\\/[a-zA-Z0-9\\-]+', method: 'delete' },
    ),
    getOptionList: new APIRequest(
      ({ branchName, optionListName }) => axiosAuthClient.get<OptionListDefinition>(`${baseUrl}/branches/${branchName}/rule/option-lists/${optionListName}`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule/option-lists\\/[a-zA-Z0-9\\-]+', method: 'get' },
    ),
    updateOptionList: new APIRequest(
      ({
        branchName, optionListName, optionList, commitMessage,
      }) => axiosAuthClient.put<OptionListDefinition>(`${baseUrl}/branches/${branchName}/rule/option-lists/${optionListName}`, { commitMessage, asset: optionList }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule/option-lists\\/[a-zA-Z0-9\\-]+', method: 'put' },
    ),
    createOptionList: new APIRequest(
      ({ branchName, optionList, commitMessage }) => axiosAuthClient.post<OptionListDefinition>(`${baseUrl}/branches/${branchName}/rule/option-lists`, { commitMessage, asset: optionList }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule/option-lists', method: 'post' },
    ),
    deleteOptionList: new APIRequest(
      ({ branchName, optionListName }) => axiosAuthClient.delete<void>(`${baseUrl}/branches/${branchName}/rule/option-lists/${optionListName}`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule/option-lists\\/[a-zA-Z0-9\\-]+', method: 'delete' },
    ),
    importOptionList: new APIRequest(
      ({ branchName, optionListName, file }) => {
        const formData = new FormData();
        formData.append('file', file);
        return axiosAuthClient.put(`${baseUrl}/branches/${branchName}/rule/option-lists/${optionListName}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then((response) => {
          const { location } = response.headers;
          const optionName = location ? location.substring(location.lastIndexOf('/') + 1) : optionListName;
          return { name: optionName };
        });
      },
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule/option-lists\\/[a-zA-Z0-9\\-]+', method: 'put' },
    ),
    exportOptionList: new APIRequest(
      ({ branchName, optionListName }) => axiosAuthClient.get(`${baseUrl}/branches/${branchName}/rule/option-lists/${optionListName}`, {
        responseType: 'blob',
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      }).then(getFile),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule/option-lists\\/[a-zA-Z0-9\\-]+', method: 'get' },
    ),
    getPriorEnquiry: new APIRequest(
      ({ branchName, priorEnquiryName }) => axiosAuthClient.get<PriorEnquiryDefinition>(`${baseUrl}/branches/${branchName}/rule/prior-enquiry-channels/${priorEnquiryName}`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/prior-enquiry-channels\\/[a-zA-Z0-9\\-]+', method: 'get' },
    ),
    updatePriorEnquiry: new APIRequest(
      ({
        branchName, priorEnquiryName, priorEnquiry, commitMessage,
      }) => axiosAuthClient.put<PriorEnquiryChannel>(`${baseUrl}/branches/${branchName}/rule/prior-enquiry-channels/${priorEnquiryName}`, { commitMessage, asset: priorEnquiry }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/prior-enquiry-channels\\/[a-zA-Z0-9\\-]+', method: 'put' },
    ),
    createPriorEnquiry: new APIRequest(
      ({ branchName, priorEnquiry, commitMessage }) => axiosAuthClient.post<PriorEnquiryDefinition>(`${baseUrl}/branches/${branchName}/rule/prior-enquiry-channels`, { commitMessage, asset: priorEnquiry }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/prior-enquiry-channels', method: 'post' },
    ),
    deletePriorEnquiry: new APIRequest(
      ({ branchName, priorEnquiryName }) => axiosAuthClient.delete<void>(`${baseUrl}/branches/${branchName}/rule/prior-enquiry-channels/${priorEnquiryName}`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/prior-enquiry-channels\\/[a-zA-Z0-9\\-]+', method: 'delete' },
    ),
    getLine: new APIRequest(
      ({ branchName, lineName }) => axiosAuthClient.get<LineDefinition>(`${baseUrl}/branches/${branchName}/rule/lines/${lineName}`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/lines\\/[a-zA-Z0-9\\-]+', method: 'get' },
    ),
    updateLine: new APIRequest(
      ({
        branchName, lineName, line, commitMessage,
      }) => axiosAuthClient.put<LineDefinition>(`${baseUrl}/branches/${branchName}/rule/lines/${lineName}`, { commitMessage, asset: line }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/lines\\/[a-zA-Z0-9\\-]+', method: 'put' },
    ),
    createLine: new APIRequest(
      ({ branchName, line, commitMessage }) => axiosAuthClient.post<LineDefinition>(`${baseUrl}/branches/${branchName}/rule/lines`, { commitMessage, asset: line }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/lines', method: 'post' },
    ),
    deleteLine: new APIRequest(
      ({ branchName, lineName }) => axiosAuthClient.delete<void>(`${baseUrl}/branches/${branchName}/rule/lines/${lineName}`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/lines\\/[a-zA-Z0-9\\-]+', method: 'delete' },
    ),
    getWrapUpLinesOrder: new APIRequest(
      ({ branchName }) => axiosAuthClient.get<WrapUpLine[]>(`${baseUrl}/branches/${branchName}/rule/wrapUpLineOrder`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/lines\\wrapUpLineOrder', method: 'get' },
    ),
    updateWrapUpLinesOrder: new APIRequest(
      ({ branchName, order, commitMessage }) => axiosAuthClient.put<WrapUpLine[]>(`${baseUrl}/branches/${branchName}/rule/wrapUpLineOrder`, { commitMessage, asset: order }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/lines\\wrapUpLineOrder', method: 'put' },
    ),
    getLineTables: new APIRequest(
      ({ branchName, lineName }) => axiosAuthClient.get<TableDefinition[]>(`${baseUrl}/branches/${branchName}/rule/lines/${lineName}/tables`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/lines\\/[a-zA-Z0-9\\-]+\\/tables', method: 'get' },
    ),
    getBranchTables: new APIRequest(
      ({ branchName }) => axiosAuthClient.get<TableDefinition[]>(`${baseUrl}/branches/${branchName}/rule/tables`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/tables', method: 'get' },
    ),
    addTable: new APIRequest(
      ({
        branchName, lineName, sheetAxis, sheet,
      }) => {
        const formData = new FormData();
        formData.append('sheet', sheet);
        formData.append('sheetAxis', sheetAxis || '');
        return axiosAuthClient.put(`${baseUrl}/branches/${branchName}/rule/lines/${lineName}/tables`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      },
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/lines\\/[a-zA-Z0-9\\-]+\\/tables', method: 'put' },
    ),
    deleteTable: new APIRequest(
      ({ branchName, lineName, name }) => axiosAuthClient.delete<void>(`${baseUrl}/branches/${branchName}/rule/lines/${lineName}/tables/${name}`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/lines\\/[a-zA-Z0-9\\-]+\\/tables\\/[a-zA-Z0-9\\-]+', method: 'delete' },
    ),
    exportTable: new APIRequest(
      ({ branchName, lineName, name }) => axiosAuthClient.get(`${baseUrl}/branches/${branchName}/rule/lines/${lineName}/tables/${name}`, { responseType: 'blob' }).then(getFile),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/lines\\/[a-zA-Z0-9\\-]+\\/tables\\/[a-zA-Z0-9\\-]+', method: 'get' },
    ),
    getLineTests: new APIRequest(
      ({ branchName, lineName }) => axiosAuthClient.get<LineTestDefinition[]>(`${baseUrl}/branches/${branchName}/rule/lines/${lineName}/tests`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/lines\\/[a-zA-Z0-9\\-]+\\/tests', method: 'get' },
    ),
    addLineTest: new APIRequest(
      ({
        branchName, lineName, name, sheet,
      }) => {
        const formData = new FormData();
        formData.append('file', sheet);
        // formData.append('forEach', forEach ? 'true' : 'false');
        return axiosAuthClient.put(`${baseUrl}/branches/${branchName}/rule/lines/${lineName}/tests/${name}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob',
        }).then(({ data }) => data);
      },
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/lines\\/[a-zA-Z0-9\\-]+\\/tests', method: 'put' },
    ),
    importForEachTests: new APIRequest(
      ({
        branchName, lineName, name, sheet,
      }) => {
        const formData = new FormData();
        formData.append('file', sheet);
        return axiosAuthClient.put(`${baseUrl}/branches/${branchName}/rule/lines/${lineName}/tests/${name}/foreach`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob',
        }).then(({ data }) => data);
      },
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/lines\\/[a-zA-Z0-9\\-]+\\/tests\\/[a-zA-Z0-9\\-]+\\/foreach', method: 'put' },
    ),
    deleteLineTest: new APIRequest(
      ({ branchName, lineName, name }) => axiosAuthClient.delete<void>(`${baseUrl}/branches/${branchName}/rule/lines/${lineName}/tests/${name}`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\//lines\\/[a-zA-Z0-9\\-]+\\/tests\\/[a-zA-Z0-9\\-]+', method: 'delete' },
    ),
    exportLineTest: new APIRequest(
      ({ branchName, lineName, name }) => axiosAuthClient.get(`${baseUrl}/branches/${branchName}/rule/lines/${lineName}/tests/${name}`, {
        responseType: 'blob',
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      }).then(getFile),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/lines\\/[a-zA-Z0-9\\-]+\\/tests\\/[a-zA-Z0-9\\-]+', method: 'get' },
    ),
    runLineTest: new APIRequest(
      ({ branchName, lineName, name }) => axiosAuthClient.post<void>(`${baseUrl}/branches/${branchName}/rule/lines/${lineName}/tests/${name}/run`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/lines\\/tests\\/[a-zA-Z0-9\\-]+\\', method: 'post' },
    ),
    exportTestTemplate: new APIRequest(
      ({ branchName, lineName, exportType }) => axiosAuthClient.get(`${baseUrl}/branches/${branchName}/rule/lines/${lineName}/tests/template?type=${exportType}`, { responseType: 'blob' }).then(getFile),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/lines\\/[a-zA-Z0-9\\-]+\\/tests\\/template', method: 'get' },
    ),
    validateBranch: new APIRequest(
      ({ branchName }) => axiosAuthClient.get<EditorValidationErrors>(`${baseUrl}/branches/${branchName}/rule/validation`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/validation\\/lines\\/[a-zA-Z0-9\\-]+', method: 'get' },
    ),
    validateLine: new APIRequest(
      ({ branchName, lineName, lineDefinition }) => axiosAuthClient.post<EditorValidationErrors>(`${baseUrl}/branches/${branchName}/rule/validation/line`, { name: lineName, lineDefinition }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/rule\\/validation\\/lines\\/[a-zA-Z0-9\\-]+', method: 'get' },
    ),
    typeaheadBucketValue: new APIRequest(
      ({ branchName, bucket, partialValue }) => axiosAuthClient.get<TypeAheadBucketValueReplacements>(`${baseUrl}/branches/${branchName}/typeahead/contribution`, { params: { bucket, partialValue } }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/typeahead\\/contribution', method: 'get' },
    ),
    typeaheadContributionBucket: new APIRequest(
      ({ branchName, expression }) => axiosAuthClient.get<TypeAheadBucketValueReplacements>(`${baseUrl}/branches/${branchName}/typeahead/contribution/bucket`, { params: { expression } }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/typeahead\\/contribution\\/bucket', method: 'get' },
    ),
    editorConfiguration: new APIRequest(
      () => axiosAuthClient.get<EditorConfiguration>(`${baseUrl}/configurations/editor`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/configurations\\/editor', method: 'get' },
    ),
  } as EditorAPI;

  

  return api;
};
