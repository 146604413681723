import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { FC } from 'react';

import './Loader.less';

export interface LoaderProps {
  isCentered?: boolean;
  size?: number;
}

const Loader: FC<LoaderProps> = ({
  isCentered = true,
  size = 50,
}) => (
  <div className={`uw-loader ${isCentered ? 'centered' : ''}`} key="content">
    <Spin spinning indicator={<LoadingOutlined style={{ fontSize: size }} spin />} />
  </div>
);

export default Loader;
