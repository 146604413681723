import { useEffect, useRef } from 'react';
import {
  DefinedUseQueryResult,
  QueryClient,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  Asset, AssetUrlVariables,
  BranchInformation,
  BucketDefinition,
  EditorConfiguration,
  EditorValidationErrors,
  LineAsset,
  LineDefinition,
  LineTestDefinition,
  LocaleAsset,
  LocaleDefinition,
  OptionDefinition,
  OptionListDefinition,
  PriorEnquiryDefinition,
  QuestionAsset,
  QuestionDefinition,
  TableDefinition,
  TestRunStatus,
  WrapUpLine,
} from '../../dtos/editor.dto';
import {
  APIErrorResponse,
  DataRequestParams,
  isAPIError,
  Paginated,
  QueryOptions,
  QueryResult,
  RequiredKey,
  useAPIMutation,
} from '../types';
import { getEditorAPI } from './api';
import {
  AddLineTest,
  AddLineTestParams,
  AddTable,
  AddTableParams,
  CreateBucket,
  CreateBucketParams,
  CreateLine,
  CreateLineParams,
  CreateLocale,
  CreateLocaleParams,
  CreateOptionList,
  CreateOptionListParams,
  CreatePriorEnquiry,
  CreatePriorEnquiryParams,
  CreateQuestion,
  CreateQuestionParams,
  DeleteBucket,
  DeleteBucketParams,
  DeleteLine,
  DeleteLineParams,
  DeleteLineTest,
  DeleteLineTestParams,
  DeleteLocale,
  DeleteLocaleParams,
  DeleteOptionList,
  DeleteOptionListParams,
  DeletePriorEnquiry,
  DeletePriorEnquiryParams,
  DeleteQuestion,
  DeleteQuestionParams,
  DeleteTable,
  DeleteTableParams,
  ExportTemplateType,
  GetBranchTablesParams,
  GetBucketParams,
  GetLineParams,
  GetLineTablesParams,
  GetLineTestsParams,
  GetLocaleParams,
  GetOptionListParams,
  GetPriorEnquiryParams,
  GetQuestionParams,
  GetQuestionsParams,
  GetWrapUpLinesOrderParams,
  ImportForEachTests,
  ImportOptionList,
  ImportOptionListParams,
  RunLineTest,
  RunLineTestParams,
  TypeAheadBucketValueCompleter,
  TypeAheadContributionBucketCompleter,
  UpdateBucket,
  UpdateBucketParams,
  UpdateLine,
  UpdateLineParams,
  UpdateLocale,
  UpdateLocaleParams,
  UpdateOptionList,
  UpdateOptionListParams,
  UpdatePriorEnquiry,
  UpdatePriorEnquiryParams,
  UpdateQuestion,
  UpdateQuestionParams,
  UpdateWrapUpLinesOrder,
  UpdateWrapUpLinesOrderParams,
  ValidateBranch,
  ValidateBranchParams,
  ValidateLine,
  ValidateLineParams,
} from './api/types';
import { handleDownloadApiError } from '../../utils/excel-api';
import { EditorQueryKeys } from './editorQueryKeys';
import { download } from '../../utils/download';

const API = getEditorAPI();

/**
 * Add new query keys here for cache refresh upon import
 */
const allQueryKeys = [
  EditorQueryKeys.BranchInfo,
  EditorQueryKeys.LinesList,
  EditorQueryKeys.BucketsList,
  EditorQueryKeys.OptionListsList,
  EditorQueryKeys.QuestionsList,
  EditorQueryKeys.LocalesList,
  EditorQueryKeys.PriorEnquiryChannelList,
  EditorQueryKeys.BucketDef,
  EditorQueryKeys.LocaleDef,
  EditorQueryKeys.QuestionDef,
  EditorQueryKeys.OptionListDef,
  EditorQueryKeys.OptionListOptionsDef,
  EditorQueryKeys.PriorEnquiryDef,
  EditorQueryKeys.LineDef,
  EditorQueryKeys.WrapUpLineOrderDef,
  EditorQueryKeys.GraphLineTables,
  EditorQueryKeys.GraphBranchTables,
  EditorQueryKeys.GraphQuestions,
  EditorQueryKeys.LineTests,
  EditorQueryKeys.ValidationErrors,
  EditorQueryKeys.TypeAheadBucketValue,
  EditorQueryKeys.TypeAheadContributionBucket,
];

// eslint-disable-next-line max-len
export const useBranchInfo = (branchName: RequiredKey, options?: QueryOptions<BranchInformation>): QueryResult<BranchInformation> => useQuery(
  [EditorQueryKeys.BranchInfo, branchName],
  async (): Promise<BranchInformation> => API.getBranchInfo.call(branchName as string),
  {
    enabled: !!branchName,
    ...options,
  },
);

export const useEditorConfiguration = (options?: QueryOptions<EditorConfiguration, any>): QueryResult<EditorConfiguration> => useQuery(
  [EditorQueryKeys.EditorConfiguration],
  async (): Promise<EditorConfiguration> => API.editorConfiguration.call(),
  options,
);

const registerGetLinesList = (queryClient: QueryClient) => queryClient.setQueryDefaults([EditorQueryKeys.LinesList], {
  queryFn: async ({ queryKey: [, branchId] }) => API.getLinesList.call(branchId as string),
});
export const useLinesList = (branchId: RequiredKey, options?: QueryOptions<LineAsset[]>): QueryResult<LineAsset[]> => useQuery(
  [EditorQueryKeys.LinesList, branchId],
  {
    enabled: !!branchId,
    ...options,
  },
);

const registerGetBucketsList = (queryClient: QueryClient) => queryClient.setQueryDefaults([EditorQueryKeys.BucketsList], {
  queryFn: async ({ queryKey: [, branchId] }) => API.getBucketsList.call(branchId as string),
});
export const useBucketList = (branchId: RequiredKey, options?: QueryOptions<Asset[]>): QueryResult<Asset[]> => useQuery(
  [EditorQueryKeys.BucketsList, branchId],
  {
    enabled: !!branchId,
    ...options,
  },
);

const registerGetOptionsLists = (queryClient: QueryClient) => queryClient.setQueryDefaults([EditorQueryKeys.OptionListsList], {
  queryFn: async ({ queryKey: [, branchId] }) => API.getOptionListList.call(branchId as string),
});
export const useOptionLists = (branchId: RequiredKey, options?: QueryOptions<Asset[]>): QueryResult<Asset[]> => useQuery(
  [EditorQueryKeys.OptionListsList, branchId],
  {
    enabled: !!branchId,
    ...options,
  },
);

const registerGetQuestionsList = (queryClient: QueryClient) => queryClient.setQueryDefaults([EditorQueryKeys.QuestionsList], {
  queryFn: async ({ queryKey: [, branchId] }) => API.getQuestionsList.call(branchId as string),
});
export const useQuestionList = (branchId: RequiredKey, options?: QueryOptions<QuestionAsset[]>): QueryResult<QuestionAsset[]> => useQuery(
  [EditorQueryKeys.QuestionsList, branchId],
  {
    enabled: !!branchId,
    ...options,
  },
);

const registerGetLocalesList = (queryClient: QueryClient) => queryClient.setQueryDefaults([EditorQueryKeys.LocalesList], {
  queryFn: async ({ queryKey: [, branchName] }) => API.getLocalesList.call(branchName as string),
});
export const useLocalesList = (branchName: RequiredKey, options?: QueryOptions<LocaleAsset[]>): QueryResult<LocaleAsset[]> => useQuery(
  [EditorQueryKeys.LocalesList, branchName],
  {
    enabled: !!branchName,
    select: (locales) => locales.sort((a) => (a.isDefault ? -1 : 0)),
    ...options,
  },
);

// eslint-disable-next-line max-len
const registerGetPriorEnquiryChannelList = (queryClient: QueryClient) => queryClient.setQueryDefaults([EditorQueryKeys.PriorEnquiryChannelList], {
  queryFn: async ({ queryKey: [, branchId] }) => API.getPriorEnquiryChannelList.call(branchId as string),
});
export const usePriorEnquiryChannelList = (branchId: RequiredKey, options?: QueryOptions<Asset[]>): QueryResult<Asset[]> => useQuery(
  [EditorQueryKeys.PriorEnquiryChannelList, branchId],
  {
    enabled: !!branchId,
    ...options,
  },
);

export const useBucket = (
  branchName: RequiredKey,
  bucketName: RequiredKey,
  options?: QueryOptions<BucketDefinition>,
): QueryResult<BucketDefinition> => useQuery(
  [EditorQueryKeys.BucketDef, branchName, bucketName],
  async (): Promise<BucketDefinition> => API.getBucket.call({ branchName, bucketName } as GetBucketParams),
  {
    enabled: !!branchName && !!bucketName,
    ...options,
  },
);

export const useUpdateBucketMutation = useAPIMutation<UpdateBucket>(EditorQueryKeys.UpdateBucket);
const registerUpdateBucket = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.UpdateBucket], {
  mutationFn: API.updateBucket.call,
  onSuccess: (_, { branchName, bucketName }: UpdateBucketParams) => {
    queryClient.removeQueries([EditorQueryKeys.BucketDef, branchName, bucketName]);
    queryClient.invalidateQueries([EditorQueryKeys.BucketsList, branchName]);
  },
});

export const useCreateBucketMutation = useAPIMutation<CreateBucket>(EditorQueryKeys.CreateBucket);
const registerCreateBucket = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.CreateBucket], {
  mutationFn: API.createBucket.call,
  onSuccess: (_, { branchName }: CreateBucketParams) => {
    queryClient.invalidateQueries([EditorQueryKeys.BucketsList, branchName]);
  },
});

export const useDeleteBucketMutation = useAPIMutation<DeleteBucket>(EditorQueryKeys.DeleteBucket);
const registerDeleteBucket = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.DeleteBucket], {
  mutationFn: API.deleteBucket.call,
  onSuccess: (_, { branchName, bucketName }: DeleteBucketParams) => {
    queryClient.removeQueries([EditorQueryKeys.BucketDef, branchName, bucketName]);
    queryClient.invalidateQueries([EditorQueryKeys.BucketsList, branchName]);
  },
  onError: (e, { branchName }: DeleteBucketParams) => {
    if (isAPIError(e)) {
      const errorResponse = e.response?.data || {} as APIErrorResponse;
      if (!errorResponse.validations?.length) {
        if (errorResponse.error === 'validation.error.bucket.missing') {
          queryClient.invalidateQueries([EditorQueryKeys.BucketsList, branchName]);
        }
      }
    }
  },
});

export const useLocale = (
  branchName: RequiredKey,
  localeName: RequiredKey,
  options?: QueryOptions<LocaleDefinition>,
): QueryResult<LocaleDefinition> => useQuery(
  [EditorQueryKeys.LocaleDef, branchName, localeName],
  async (): Promise<LocaleDefinition> => API.getLocale.call({ branchName, localeName } as GetLocaleParams),
  {
    enabled: !!branchName && !!localeName,
    ...options,
  },
);

export const useUpdateLocaleMutation = useAPIMutation<UpdateLocale>(EditorQueryKeys.UpdateLocale);
const registerUpdateLocale = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.UpdateLocale], {
  mutationFn: API.updateLocale.call,
  onSuccess: (_, { branchName, localeName }: UpdateLocaleParams) => {
    queryClient.removeQueries([EditorQueryKeys.LocaleDef, branchName, localeName]);
    queryClient.invalidateQueries([EditorQueryKeys.LocalesList, branchName]);
  },
});

export const useCreateLocaleMutation = useAPIMutation<CreateLocale>(EditorQueryKeys.CreateLocale);
const registerCreateLocale = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.CreateLocale], {
  mutationFn: API.createLocale.call,
  onSuccess: (_, { branchName }: CreateLocaleParams) => {
    queryClient.invalidateQueries([EditorQueryKeys.LocalesList, branchName]);
  },
});

export const useDeleteLocaleMutation = useAPIMutation<DeleteLocale>(EditorQueryKeys.DeleteLocale);
const registerDeleteLocale = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.DeleteLocale], {
  mutationFn: API.deleteLocale.call,
  onSuccess: (_, { branchName, localeName }: DeleteLocaleParams) => {
    queryClient.removeQueries([EditorQueryKeys.LocaleDef, branchName, localeName]);
    queryClient.invalidateQueries([EditorQueryKeys.LocalesList, branchName]);
  },
});

export const useQuestion = (
  branchId: RequiredKey,
  questionName: RequiredKey,
  options?: QueryOptions<QuestionDefinition>,
): QueryResult<QuestionDefinition> => useQuery(
  [EditorQueryKeys.QuestionDef, branchId, questionName],
  async (): Promise<QuestionDefinition> => API.getQuestion.call({ branchName: branchId, questionName } as GetQuestionParams),
  {
    enabled: !!branchId && !!questionName,
    ...options,
  },
);

export const useQuestions = (
  branchName: RequiredKey,
  questionNames: string[],
  options?: QueryOptions<QuestionDefinition[]>,
): QueryResult<QuestionDefinition[]> => {
  const queryClient = useQueryClient();
  const timestamp = useRef(Date.now());
  const oldNames = useRef<string[]>([]);
  const names = useRef<string[]>(questionNames);

  useEffect(() => {
    names.current = questionNames;
    const newNames = questionNames.filter((name) => !oldNames.current.some((n) => n === name));
    if (newNames.length > 1) {
      timestamp.current = Date.now();
      queryClient.invalidateQueries([EditorQueryKeys.GraphQuestions, branchName]);
    } else {
      const removedNames = oldNames.current.filter((name) => !questionNames.some((n) => n === name));
      if (removedNames.length) {
        queryClient.setQueryData<QuestionDefinition[]>(
          [EditorQueryKeys.GraphQuestions, branchName],
          (data) => (data || []).filter((question) => !removedNames.includes(question.name)),
        );
      }
      if (newNames.length) {
        timestamp.current = Date.now();
        const promiseTimestamp = timestamp.current;
        API.getQuestion.call({ branchName, questionName: newNames[0] } as GetQuestionParams).then((question) => {
          queryClient.setQueryData<QuestionDefinition[]>(
            [EditorQueryKeys.GraphQuestions, branchName],
            (data) => (promiseTimestamp === timestamp.current ? [...(data || []), question] : (data || [])),
          );
        }).catch((err) => {
          // We want any validations to be handled by the validation section on the enquiry lines page
          console.log(`Error with status code ${err.response.status}`);
        });
      }
    }
  }, [questionNames]);

  useEffect(() => {
    oldNames.current = questionNames;
  });

  return useQuery(
    [EditorQueryKeys.GraphQuestions, branchName],
    async (): Promise<QuestionDefinition[]> => API.searchQuestions.call({ branchName, questionNames: names.current } as GetQuestionsParams),
    {
      enabled: !!branchName && !!questionNames.length,
      ...options,
    },
  );
};

export const useUpdateQuestionMutation = useAPIMutation<UpdateQuestion>(EditorQueryKeys.UpdateQuestion);
const registerUpdateQuestion = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.UpdateQuestion], {
  mutationFn: API.updateQuestion.call,
  onSuccess: (_, { branchName, questionName }: UpdateQuestionParams) => {
    queryClient.removeQueries([EditorQueryKeys.QuestionDef, branchName, questionName]);
    queryClient.invalidateQueries([EditorQueryKeys.QuestionsList, branchName]);
  },
});

export const useCreateQuestionMutation = useAPIMutation<CreateQuestion>(EditorQueryKeys.CreateQuestion);
const registerCreateQuestion = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.CreateQuestion], {
  mutationFn: API.createQuestion.call,
  onSuccess: (_, { branchName }: CreateQuestionParams) => {
    queryClient.invalidateQueries([EditorQueryKeys.QuestionsList, branchName]);
  },
});

export const useDeleteQuestionMutation = useAPIMutation<DeleteQuestion>(EditorQueryKeys.DeleteQuestion);
const registerDeleteQuestion = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.DeleteQuestion], {
  mutationFn: API.deleteQuestion.call,
  onSuccess: (_, { branchName, questionName }: DeleteQuestionParams) => {
    queryClient.invalidateQueries([EditorQueryKeys.QuestionsList, branchName]);
    queryClient.removeQueries([EditorQueryKeys.QuestionDef, branchName, questionName]);
  },
  onError: (e, { branchName }: DeleteQuestionParams) => {
    if (isAPIError(e)) {
      const errorResponse = e.response?.data || {} as APIErrorResponse;
      if (!errorResponse.validations?.length) {
        if (errorResponse.error === 'validation.error.question.missing') {
          queryClient.invalidateQueries([EditorQueryKeys.QuestionsList, branchName]);
        }
      }
    }
  },
});

export const useOptionList = (
  branchId: RequiredKey,
  optionListName: RequiredKey,
  options?: QueryOptions<OptionListDefinition>,
): QueryResult<OptionListDefinition> => useQuery(
  [EditorQueryKeys.OptionListDef, branchId, optionListName],
  async () => API.getOptionList.call({ branchName: branchId, optionListName } as GetOptionListParams).then(),
  {
    enabled: !!branchId && !!optionListName,
    ...options,
  },
);

export const useUpdateOptionListMutation = useAPIMutation<UpdateOptionList>(EditorQueryKeys.UpdateOptionList);
const registerUpdateOptionList = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.UpdateOptionList], {
  mutationFn: API.updateOptionList.call,
  onSuccess: (_, { branchName, optionListName }: UpdateOptionListParams) => {
    queryClient.removeQueries([EditorQueryKeys.OptionListDef, branchName, optionListName]);
    queryClient.invalidateQueries([EditorQueryKeys.OptionListsList, branchName]).then();
  },
});

export const useCreateOptionListMutation = useAPIMutation<CreateOptionList>(EditorQueryKeys.CreateOptionList);
const registerCreateOptionList = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.CreateOptionList], {
  mutationFn: API.createOptionList.call,
  onSuccess: (_, { branchName }: CreateOptionListParams) => {
    queryClient.invalidateQueries([EditorQueryKeys.OptionListsList, branchName]).then();
  },
});

export const useDeleteOptionListMutation = useAPIMutation<DeleteOptionList>(EditorQueryKeys.DeleteOptionList);
const registerDeleteOptionList = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.DeleteOptionList], {
  mutationFn: API.deleteOptionList.call,
  onSuccess: (_, { branchName, optionListName }: DeleteOptionListParams) => {
    queryClient.removeQueries([EditorQueryKeys.OptionListDef, branchName, optionListName]);
    queryClient.invalidateQueries([EditorQueryKeys.OptionListsList, branchName]).then();
    queryClient.invalidateQueries([EditorQueryKeys.OptionListOptionsDef, branchName, optionListName]).then();
  },
});

/**
 * @Deprecated - this has been left in for now... it is used to proxy a call for Paginated OptionDefinition belonging
 * to an OptionList. This was used to populate a drop-drown for option backed buckets. The auto-completion functionality
 * once integrated should return the values as any expression can be used to contribute to a bucket.
 * @param queryClient - to initialise the default query on
 */
// eslint-disable-next-line max-len
const registerGetOptionListOptions = (queryClient: QueryClient) => queryClient.setQueryDefaults([EditorQueryKeys.OptionListOptionsDef], {
  queryFn: async ({ queryKey: [, branchId, optionListName, params], pageParam }): Promise<Paginated<OptionDefinition>> => API
    .getOptionList.call({
      branchName: branchId,
      optionListName,
      params: { ...params as DataRequestParams, ...pageParam },
    } as GetOptionListParams).then((optionListDefinition) => ({
      items: optionListDefinition?.options ? optionListDefinition?.options : [],
      total: optionListDefinition?.options.length,
      limit: optionListDefinition?.options.length,
    } as Paginated<OptionDefinition>)),
});

export const exportOptionList = download(API.exportOptionList.call, ({ optionListName }) => `${optionListName}.xlsx`);

export const useImportOptionListMutation = useAPIMutation<ImportOptionList>(EditorQueryKeys.ImportOptionList);
const registerImportOptionList = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.ImportOptionList], {
  mutationFn: API.importOptionList.call,
  onSuccess: (createdOptionList, { branchName }: ImportOptionListParams) => {
    queryClient.invalidateQueries([EditorQueryKeys.OptionListsList, branchName]).then();
    queryClient.invalidateQueries([EditorQueryKeys.OptionListDef, branchName, createdOptionList.name]).then();
    queryClient.invalidateQueries([EditorQueryKeys.OptionListOptionsDef, branchName, createdOptionList.name]).then();
  },
});

export const usePriorEnquiry = (
  branchName: RequiredKey,
  priorEnquiryName: RequiredKey,
  options?: QueryOptions<PriorEnquiryDefinition>,
): QueryResult<PriorEnquiryDefinition> => useQuery(
  [EditorQueryKeys.PriorEnquiryDef, branchName, priorEnquiryName],
  async () => API.getPriorEnquiry.call({ branchName, priorEnquiryName } as GetPriorEnquiryParams),
  {
    enabled: !!branchName && !!priorEnquiryName,
    ...options,
  },
);

export const useUpdatePriorEnquiryMutation = useAPIMutation<UpdatePriorEnquiry>(EditorQueryKeys.UpdatePriorEnquiry);
const registerUpdatePriorEnquiry = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.UpdatePriorEnquiry], {
  mutationFn: API.updatePriorEnquiry.call,
  onSuccess: (_, { branchName, priorEnquiryName }: UpdatePriorEnquiryParams) => {
    queryClient.removeQueries([EditorQueryKeys.PriorEnquiryDef, branchName, priorEnquiryName]);
    queryClient.invalidateQueries([EditorQueryKeys.PriorEnquiryChannelList, branchName]);
  },
});

export const useCreatePriorEnquiryMutation = useAPIMutation<CreatePriorEnquiry>(EditorQueryKeys.CreatePriorEnquiry);
const registerCreatePriorEnquiry = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.CreatePriorEnquiry], {
  mutationFn: API.createPriorEnquiry.call,
  onSuccess: (_, { branchName }: CreatePriorEnquiryParams) => {
    queryClient.invalidateQueries([EditorQueryKeys.PriorEnquiryChannelList, branchName]);
  },
});

export const useDeletePriorEnquiryMutation = useAPIMutation<DeletePriorEnquiry>(EditorQueryKeys.DeletePriorEnquiry);
const registerDeletePriorEnquiry = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.DeletePriorEnquiry], {
  mutationFn: API.deletePriorEnquiry.call,
  onSuccess: (_, { branchName, priorEnquiryName }: DeletePriorEnquiryParams) => {
    queryClient.removeQueries([EditorQueryKeys.PriorEnquiryDef, branchName, priorEnquiryName]);
    queryClient.invalidateQueries([EditorQueryKeys.PriorEnquiryChannelList, branchName]);
  },
  onError: (e, { branchName }: DeletePriorEnquiryParams) => {
    if (isAPIError(e)) {
      const errorResponse = e.response?.data || {} as APIErrorResponse;
      if (!errorResponse.validations?.length) {
        if (errorResponse.error === 'validation.error.priory-enquiry.missing') {
          queryClient.invalidateQueries([EditorQueryKeys.PriorEnquiryChannelList, branchName]);
        }
      }
    }
  },
});

export const useLine = (
  branchName: RequiredKey,
  lineName: RequiredKey,
  options?: QueryOptions<LineDefinition>,
): QueryResult<LineDefinition> => useQuery(
  [EditorQueryKeys.LineDef, branchName, lineName],
  async () => API.getLine.call({ branchName, lineName } as GetLineParams),
  {
    enabled: !!branchName && !!lineName,
    ...options,
  },
);

export const useUpdateLineMutation = useAPIMutation<UpdateLine>(EditorQueryKeys.UpdateLine);
const registerUpdateLine = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.UpdateLine], {
  mutationFn: API.updateLine.call,
  onSuccess: (_, { branchName, lineName }: UpdateLineParams) => {
    queryClient.removeQueries([EditorQueryKeys.LineDef, branchName, lineName]);
    queryClient.invalidateQueries([EditorQueryKeys.LinesList, branchName]).then();
    queryClient.invalidateQueries([EditorQueryKeys.QuestionsList, branchName]).then();
    queryClient.invalidateQueries([EditorQueryKeys.WrapUpLineOrderDef, branchName]).then();
    queryClient.invalidateQueries([EditorQueryKeys.GraphLineTables, branchName, lineName]).then();
    queryClient.invalidateQueries([EditorQueryKeys.LineTests, branchName, lineName]).then();
  },
});

export const clearEditorCaches = (queryClient: QueryClient, branchName: string) => {
  allQueryKeys.forEach((key) => queryClient.invalidateQueries([key, branchName]));
};

export const useCreateLineMutation = useAPIMutation<CreateLine>(EditorQueryKeys.CreateLine);
const registerCreateLine = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.CreateLine], {
  mutationFn: API.createLine.call,
  onSuccess: (_, { branchName }: CreateLineParams) => {
    queryClient.invalidateQueries([EditorQueryKeys.LinesList, branchName]).then();
    queryClient.invalidateQueries([EditorQueryKeys.WrapUpLineOrderDef, branchName]).then();
  },
});

export const useDeleteLineMutation = useAPIMutation<DeleteLine>(EditorQueryKeys.DeleteLine);
const registerDeleteLine = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.DeleteLine], {
  mutationFn: API.deleteLine.call,
  onSuccess: (_, { branchName, lineName }: DeleteLineParams) => {
    queryClient.removeQueries([EditorQueryKeys.LineDef, branchName, lineName]);
    queryClient.invalidateQueries([EditorQueryKeys.LinesList, branchName]).then();
    queryClient.invalidateQueries([EditorQueryKeys.WrapUpLineOrderDef, branchName]).then();
    queryClient.invalidateQueries([EditorQueryKeys.GraphLineTables, branchName, lineName]).then();
    queryClient.invalidateQueries([EditorQueryKeys.LineTests, branchName, lineName]).then();
  },
});

export const useWrapUpLinesOrder = (
  branchName: RequiredKey,
  options?: QueryOptions<WrapUpLine[]>,
): QueryResult<WrapUpLine[]> => useQuery(
  [EditorQueryKeys.WrapUpLineOrderDef, branchName],
  async () => API.getWrapUpLinesOrder.call({ branchName } as GetWrapUpLinesOrderParams),
  {
    enabled: !!branchName,
    ...options,
  },
);

export const useUpdateWrapUpLinesOrderMutation = useAPIMutation<UpdateWrapUpLinesOrder>(EditorQueryKeys.UpdateWrapUpLinesOrder);
// eslint-disable-next-line max-len
const registerUpdateWrapUpLinesOrder = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.UpdateWrapUpLinesOrder], {
  mutationFn: API.updateWrapUpLinesOrder.call,
  onSuccess: (_, { branchName }: UpdateWrapUpLinesOrderParams) => {
    queryClient.invalidateQueries([EditorQueryKeys.WrapUpLineOrderDef, branchName]).then();
  },
});

// eslint-disable-next-line max-len
export const useLineTables = (branchName: RequiredKey, lineName: RequiredKey, options?: QueryOptions<TableDefinition[]>): QueryResult<TableDefinition[]> => useQuery(
  [EditorQueryKeys.GraphLineTables, branchName, lineName],
  async (): Promise<TableDefinition[]> => API.getLineTables.call({ branchName, lineName } as GetLineTablesParams),
  {
    enabled: !!branchName && !!lineName,
    ...options,
  },
);

// eslint-disable-next-line max-len
export const useBranchTables = (branchName: RequiredKey, options?: QueryOptions<TableDefinition[]>): QueryResult<TableDefinition[]> => useQuery(
  [EditorQueryKeys.GraphBranchTables, branchName],
  async (): Promise<TableDefinition[]> => API.getBranchTables.call({ branchName } as GetBranchTablesParams),
  {
    enabled: !!branchName,
    ...options,
  },
);

export const useCreateTableMutation = useAPIMutation<AddTable>(EditorQueryKeys.CreateTable);
const registerCreateTable = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.CreateTable], {
  mutationFn: API.addTable.call,
  onSuccess: (_, { branchName, lineName }: AddTableParams) => {
    queryClient.invalidateQueries([EditorQueryKeys.GraphLineTables, branchName, lineName]);
    queryClient.invalidateQueries([EditorQueryKeys.GraphBranchTables, branchName]);
  },
});

export const useDeleteTableMutation = useAPIMutation<DeleteTable>(EditorQueryKeys.DeleteTable);
const registerDeleteTable = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.DeleteTable], {
  mutationFn: API.deleteTable.call,
  onSuccess: (_, { branchName, lineName }: DeleteTableParams) => {
    queryClient.invalidateQueries([EditorQueryKeys.GraphLineTables, branchName, lineName]);
    queryClient.invalidateQueries([EditorQueryKeys.GraphBranchTables, branchName]);
  },
});

export const exportTable = download(API.exportTable.call, ({ name }) => `${name}.xlsx`);

// eslint-disable-next-line max-len
export const useLineTests = (branchName: RequiredKey, lineName: RequiredKey, options?: QueryOptions<LineTestDefinition[]>): QueryResult<LineTestDefinition[]> => useQuery(
  [EditorQueryKeys.LineTests, branchName, lineName],
  async (): Promise<LineTestDefinition[]> => API.getLineTests.call({ branchName, lineName } as GetLineTestsParams),
  {
    enabled: !!branchName && !!lineName,
    ...options,
  },
);

export const useCreateLineTestMutation = useAPIMutation<AddLineTest>(EditorQueryKeys.CreateLineTest);
const registerCreateLineTest = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.CreateLineTest], {
  mutationFn: API.addLineTest.call,
  onSuccess: (_, { branchName, lineName }: AddLineTestParams) => {
    queryClient.invalidateQueries([EditorQueryKeys.LineTests, branchName, lineName]);
  },
  onError: async (error) => handleDownloadApiError(error, 'validation.error.asset.line.test.spreadsheet.errors'),
});

export const useImportForEachTestsMutation = useAPIMutation<ImportForEachTests>(EditorQueryKeys.ImportForEachTests);
const registerImportForEachTests = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.ImportForEachTests], {
  mutationFn: API.importForEachTests.call,
  onSuccess: (_, { branchName, lineName }: AddLineTestParams) => {
    queryClient.invalidateQueries([EditorQueryKeys.LineTests, branchName, lineName]);
  },
  onError: async (error) => handleDownloadApiError(error, 'error.upload.xlsx.foreach.validation'),
});

export const useDeleteLineTestMutation = useAPIMutation<DeleteLineTest>(EditorQueryKeys.DeleteLineTest);
const registerDeleteLineTest = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.DeleteLineTest], {
  mutationFn: API.deleteLineTest.call,
  onSuccess: (_, { branchName, lineName }: DeleteLineTestParams) => {
    queryClient.invalidateQueries([EditorQueryKeys.LineTests, branchName, lineName]);
  },
});

export const exportLineTest = download(API.exportLineTest.call, ({ name }) => `${name}.xlsx`);

export const useRunLineTestMutation = useAPIMutation<RunLineTest>(EditorQueryKeys.RunLineTest);
const registerRunLineTest = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.RunLineTest], {
  /*
   Not great that we have to force update the state of the definition like this, but currently there is no "status"
   api for the line test run,so this will allow us to at least notify the user the run is happening, until the request
   completes and the real state is returned.
   */
  mutationFn: (params: RunLineTestParams) => {
    // eslint-disable-next-line max-len
    queryClient.setQueryData([EditorQueryKeys.LineTests, params.branchName, params.lineName], (tests: LineTestDefinition[] | undefined) => tests?.map(
      (test) => (test.name === params.name ? ({ ...test, status: TestRunStatus.PENDING }) : test),
    ) || []);
    return API.runLineTest.call(params);
  },
  onSuccess: (_, { branchName, lineName }: DeleteLineTestParams) => {
    queryClient.invalidateQueries([EditorQueryKeys.LineTests, branchName, lineName]);
  },
});

export const exportTestTemplate = download(API.exportTestTemplate.call, (params) => (params.exportType === ExportTemplateType.BLANK ? 'Template_Blank.xlsx' : 'Template_ForEach.xlsx'));

// eslint-disable-next-line max-len
export const useValidationErrors = (branchId: RequiredKey, options?: QueryOptions<EditorValidationErrors>): DefinedUseQueryResult<undefined | EditorValidationErrors, AxiosError<EditorValidationErrors> | Error> => useQuery(
  [EditorQueryKeys.ValidationErrors, branchId],
  {
    enabled: !!branchId,
    staleTime: Infinity,
    cacheTime: Infinity,
    initialData: {},
    ...options,
  },
);

export const useValidateBranchMutation = useAPIMutation<ValidateBranch>(EditorQueryKeys.ValidateBranch);
const registerValidateBranch = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.ValidateBranch], {
  mutationFn: API.validateBranch.call,
  onSuccess: (validationErrors: EditorValidationErrors, { branchName }: ValidateBranchParams) => {
    queryClient.setQueryData([EditorQueryKeys.ValidationErrors, branchName], () => validationErrors);
  },
});

export const useValidateLineMutation = useAPIMutation<ValidateLine>(EditorQueryKeys.ValidateLine);
const registerValidateLine = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.ValidateLine], {
  mutationFn: API.validateLine.call,
  onSuccess: (validationErrors: EditorValidationErrors, { branchName }: ValidateLineParams) => {
    queryClient.setQueryData([EditorQueryKeys.ValidationErrors, branchName], () => validationErrors);
  },
});

export const useTypeAheadBucketValueMutation = useAPIMutation<TypeAheadBucketValueCompleter>(EditorQueryKeys.TypeAheadBucketValue);
// eslint-disable-next-line max-len
const registerTypeAheadBucketValue = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.TypeAheadBucketValue], {
  mutationFn: API.typeaheadBucketValue.call,
});

// eslint-disable-next-line max-len
export const useTypeAheadContributionBucketMutation = useAPIMutation<TypeAheadContributionBucketCompleter>(EditorQueryKeys.TypeAheadContributionBucket);
// eslint-disable-next-line max-len
const registerTypeAheadContributionBucket = (queryClient: QueryClient) => queryClient.setMutationDefaults([EditorQueryKeys.TypeAheadContributionBucket], {
  mutationFn: API.typeaheadContributionBucket.call,
});

/**
 * Because useParam from react router doesn't reflect the url parameters accurately in some scenarios where navigate is used
 * (it provides the old value for parameters before navigate), parsing parameters from url manually is used to prevent react-query errors.
 * Examples:
 * /editor/branch1/question -> urlBranchName: branch1, urlLineName: undefined, urlAssetName: undefined
 * /editor/branch1/question/Q1 -> urlBranchName: branch1, urlLineName: undefined, urlAssetName: Q1
 * /editor/branch1/enquiry-line/Line1/question/Q1 -> urlBranchName: branch1, urlLineName: Line1, urlAssetName: Q1
 */
export const parseAssetUrlVariables = (pathname: string): AssetUrlVariables => {
  const regex = /\/editor\/([^\\/]+)(\/[^\\/]+\/([^\\/]+))?\/[^\\/]+(\/(.*))?/g;
  const result = regex.exec(pathname);
  if (result) {
    return {
      urlBranchName: result[1] ? decodeURIComponent(result[1]) : result[1],
      urlLineName: result[3] ? decodeURIComponent(result[3]) : result[3],
      urlAssetName: result[5] ? decodeURIComponent(result[5]) : result[5],
    };
  } else {
    return {} as AssetUrlVariables;
  }
};

export const registerEditorCalls = (queryClient: QueryClient) => {
  const registrations = [
    registerGetLinesList,
    registerGetBucketsList,
    registerGetOptionsLists,
    registerGetQuestionsList,
    registerGetLocalesList,
    registerGetPriorEnquiryChannelList,
    registerUpdateBucket,
    registerCreateBucket,
    registerDeleteBucket,
    registerUpdateLocale,
    registerCreateLocale,
    registerDeleteLocale,
    registerUpdateQuestion,
    registerCreateQuestion,
    registerDeleteQuestion,
    registerUpdateOptionList,
    registerCreateOptionList,
    registerDeleteOptionList,
    registerImportOptionList,
    registerGetOptionListOptions,
    registerUpdatePriorEnquiry,
    registerCreatePriorEnquiry,
    registerDeletePriorEnquiry,
    registerUpdateLine,
    registerCreateLine,
    registerDeleteLine,
    registerUpdateWrapUpLinesOrder,
    registerCreateTable,
    registerDeleteTable,
    registerCreateLineTest,
    registerImportForEachTests,
    registerDeleteLineTest,
    registerRunLineTest,
    registerValidateBranch,
    registerValidateLine,
    registerTypeAheadBucketValue,
    registerTypeAheadContributionBucket,
  ];
  registrations.forEach((register) => register(queryClient));
};
