import {
  // eslint-disable-next-line max-len
  Asset,
  BranchInformation,
  BucketDefinition,
  EditorConfiguration,
  EditorValidationErrors,
  LineAsset,
  LineDefinition,
  LineTestDefinition,
  LocaleAsset,
  LocaleDefinition,
  OptionListDefinition,
  PriorEnquiryDefinition,
  QuestionAsset,
  QuestionDefinition,
  TableDefinition,
  WrapUpLine,
} from '../../../dtos/editor.dto';
import {
  API, APIRequest, DataRequestParams, FileDownload, Paginated,
} from '../../types';

export type GetBranchInfo = APIRequest<BranchInformation, string>;

export type GetLinesList = APIRequest<LineAsset[], string>;

export type GetBucketsList = APIRequest<Asset[], string>;

export type GetOptionsList = APIRequest<Asset[], string>;

export type GetQuestionsList = APIRequest<QuestionAsset[], string>;

export type GetLocalesList = APIRequest<LocaleAsset[], string>;

export type GetPriorEnquiryChannelList = APIRequest<Asset[], string>;

export interface GetBucketParams {
  branchName: string;
  bucketName: string;
}
export type GetBucket = APIRequest<BucketDefinition, GetBucketParams>;

export interface UpdateBucketParams {
  branchName: string;
  bucketName: string;
  bucket: BucketDefinition;
  commitMessage?: string;
}
export type UpdateBucket = APIRequest<BucketDefinition, UpdateBucketParams>;

export interface CreateBucketParams {
  branchName: string;
  bucket: BucketDefinition;
  commitMessage?: string;
}
export type CreateBucket = APIRequest<BucketDefinition, CreateBucketParams>;

export interface DeleteBucketParams {
  branchName: string;
  bucketName: string;
}
export type DeleteBucket = APIRequest<void, DeleteBucketParams>;

export interface GetLocaleParams {
  branchName: string;
  localeName: string;
}
export type GetLocale = APIRequest<LocaleDefinition, GetLocaleParams>;

export interface UpdateLocaleParams {
  branchName: string;
  localeName: string;
  locale: LocaleDefinition;
  commitMessage?: string;
}
export type UpdateLocale = APIRequest<void, UpdateLocaleParams>;

export interface CreateLocaleParams {
  branchName: string;
  locale: LocaleDefinition;
  commitMessage?: string;
}
export type CreateLocale = APIRequest<void, CreateLocaleParams>;

export interface DeleteLocaleParams {
  branchName: string;
  localeName: string;
}
export type DeleteLocale = APIRequest<void, DeleteLocaleParams>;

export interface GetQuestionParams {
  branchName: string;
  questionName: string;
}
export type GetQuestion = APIRequest<QuestionDefinition, GetQuestionParams>;

export interface GetQuestionsParams {
  branchName: string;
  questionNames: string[];
}
export type GetQuestions = APIRequest<QuestionDefinition[], GetQuestionsParams>;

export interface UpdateQuestionParams {
  branchName: string;
  questionName: string;
  question: QuestionDefinition;
  commitMessage?: string;
}
export type UpdateQuestion = APIRequest<void, UpdateQuestionParams>;

export interface CreateQuestionParams {
  branchName: string;
  question: QuestionDefinition;
  commitMessage?: string;
}
export type CreateQuestion = APIRequest<void, CreateQuestionParams>;

export interface DeleteQuestionParams {
  branchName: string;
  questionName: string;
}
export type DeleteQuestion = APIRequest<void, DeleteQuestionParams>;

export interface GetOptionListParams {
  branchName: string;
  optionListName: string;
}
export type GetOptionList = APIRequest<OptionListDefinition, GetOptionListParams>;

export interface UpdateOptionListParams {
  branchName: string;
  optionListName: string;
  optionList: OptionListDefinition;
  commitMessage?: string;
}
export type UpdateOptionList = APIRequest<OptionListDefinition, UpdateOptionListParams>;

export interface CreateOptionListParams {
  branchName: string;
  optionList: OptionListDefinition;
  commitMessage?: string;
}
export type CreateOptionList = APIRequest<OptionListDefinition, CreateOptionListParams>;

export interface DeleteOptionListParams {
  branchName: string;
  optionListName: string;
}
export type DeleteOptionList = APIRequest<void, DeleteOptionListParams>;

export interface ImportOptionListParams {
  branchName: string;
  optionListName: string;
  file: File;
}
export interface ImportOptionListResponse {
  name : string,
}
export type ImportOptionList = APIRequest<ImportOptionListResponse, ImportOptionListParams>; //  TODO what to expose here?

export interface ExportOptionListParams {
  branchName: string;
  optionListName: string;
}
export type ExportOptionList = APIRequest<FileDownload, ExportOptionListParams>;

export interface GetPriorEnquiryParams {
  branchName: string;
  priorEnquiryName: string;
}
export type GetPriorEnquiry = APIRequest<PriorEnquiryDefinition, GetPriorEnquiryParams>;

export interface UpdatePriorEnquiryParams {
  branchName: string;
  priorEnquiryName: string;
  priorEnquiry: PriorEnquiryDefinition;
  commitMessage?: string;
}
export type UpdatePriorEnquiry = APIRequest<PriorEnquiryDefinition, UpdatePriorEnquiryParams>;

export interface CreatePriorEnquiryParams {
  branchName: string;
  priorEnquiry: PriorEnquiryDefinition;
  commitMessage?: string;
}
export type CreatePriorEnquiry = APIRequest<PriorEnquiryDefinition, CreatePriorEnquiryParams>;

export interface DeletePriorEnquiryParams {
  branchName: string;
  priorEnquiryName: string;
}
export type DeletePriorEnquiry = APIRequest<void, DeletePriorEnquiryParams>;

export interface GetLineParams {
  branchName: string;
  lineName: string;
}
export type GetLine = APIRequest<LineDefinition, GetLineParams>;

export interface UpdateLineParams {
  branchName: string;
  lineName: string;
  line: LineDefinition;
  commitMessage?: string;
}
export type UpdateLine = APIRequest<LineDefinition, UpdateLineParams>;

export interface CreateLineParams {
  branchName: string;
  line: LineDefinition;
  commitMessage?: string;
}
export type CreateLine = APIRequest<LineDefinition, CreateLineParams>;

export interface DeleteLineParams {
  branchName: string;
  lineName: string;
}
export type DeleteLine = APIRequest<void, DeleteLineParams>;

export interface GetWrapUpLinesOrderParams {
  branchName: string;
}
export type GetWrapUpLinesOrder = APIRequest<WrapUpLine[], GetWrapUpLinesOrderParams>;

export interface UpdateWrapUpLinesOrderParams {
  branchName: string;
  order: WrapUpLine[];
  commitMessage?: string;
}
export type UpdateWrapUpLinesOrder = APIRequest<WrapUpLine[], UpdateWrapUpLinesOrderParams>;

export interface GetLineTablesParams {
  branchName: string;
  lineName: string;
}
export type GetLineTables = APIRequest<TableDefinition[], GetLineTablesParams>;

export interface GetBranchTablesParams {
  branchName: string;
}
export type GetBranchTables = APIRequest<TableDefinition[], GetBranchTablesParams>;

export interface DeleteTableParams {
  branchName: string;
  lineName: string;
  name: string;
}
export type DeleteTable = APIRequest<void, DeleteTableParams>;

export interface AddTableParams {
  branchName: string;
  lineName: string;
  sheetAxis?: string;
  sheet: File;
}
export type AddTable = APIRequest<void, AddTableParams>;

export interface ExportTableParams {
  branchName: string;
  lineName: string;
  name: string;
}
export type ExportTable = APIRequest<FileDownload, ExportTableParams>;

export interface GetLineTestsParams {
  branchName: string;
  lineName: string;
}
export type GetLineTests = APIRequest<LineTestDefinition[], GetLineTestsParams>;

export interface DeleteLineTestParams {
  branchName: string;
  lineName: string;
  name: string;
}
export type DeleteLineTest = APIRequest<void, DeleteLineTestParams>;

export interface AddLineTestParams {
  branchName: string;
  lineName: string;
  name: string;
  forEach?: boolean;
  sheet: File;
}
export type AddLineTest = APIRequest<void, AddLineTestParams>;

export type ImportForEachTests = APIRequest<void, AddLineTestParams>;

export interface ExportLineTestParams {
  branchName: string;
  lineName: string;
  name: string;
}
export type ExportLineTest = APIRequest<FileDownload, ExportLineTestParams>;

export interface RunLineTestParams {
  branchName: string;
  // TODO likely unnecessary
  lineName: string;
  name: string;
}
export type RunLineTest = APIRequest<LineTestDefinition, RunLineTestParams>;

export enum ExportTemplateType {
  BLANK = 'BLANK',
  FOR_EACH = 'FOR_EACH',
}

export interface ExportTestTemplateParams {
  branchName: string,
  lineName: string,
  exportType: ExportTemplateType
}

export type ExportTestTemplate = APIRequest<FileDownload, ExportTestTemplateParams>;

export interface ValidateBranchParams {
  branchName: string;
}
export type ValidateBranch = APIRequest<EditorValidationErrors, ValidateBranchParams>;

export interface ValidateLineParams {
  branchName: string;
  lineName?: string;
  lineDefinition: LineDefinition;
}

export type ValidateLine = APIRequest<EditorValidationErrors, ValidateLineParams>;

export interface TypeAheadBucketValueCompleterParams {
  branchName: string,
  bucket: string,
  partialValue: string,
}
export interface TypeAheadBucketValueReplacements {
  replacements : string[],
}
export type TypeAheadBucketValueCompleter = APIRequest<TypeAheadBucketValueReplacements, TypeAheadBucketValueCompleterParams>;

export interface TypeAheadContributionBucketCompleterParams {
  branchName: string,
  expression: string,
}
export interface TypeAheadContributionBucketReplacements {
  replacements : string[],
}
export type TypeAheadContributionBucketCompleter =
  APIRequest<TypeAheadContributionBucketReplacements, TypeAheadContributionBucketCompleterParams>;

export type EditorConfigurationLoader =
  APIRequest<EditorConfiguration>;

export interface EditorAPI extends API {
  getBranchInfo: GetBranchInfo;
  getLinesList: GetLinesList;
  getBucketsList: GetBucketsList;
  getOptionListList: GetOptionsList;
  getQuestionsList: GetQuestionsList;
  getLocalesList: GetLocalesList;
  getPriorEnquiryChannelList: GetPriorEnquiryChannelList;
  getBucket: GetBucket;
  updateBucket: UpdateBucket;
  createBucket: CreateBucket;
  deleteBucket: DeleteBucket;
  getLocale: GetLocale;
  updateLocale: UpdateLocale;
  createLocale: CreateLocale;
  deleteLocale: DeleteLocale;
  getQuestion: GetQuestion;
  searchQuestions: GetQuestions;
  updateQuestion: UpdateQuestion;
  createQuestion: CreateQuestion;
  deleteQuestion: DeleteQuestion;
  getOptionList: GetOptionList;
  updateOptionList: UpdateOptionList;
  createOptionList: CreateOptionList;
  deleteOptionList: DeleteOptionList;
  importOptionList: ImportOptionList;
  exportOptionList: ExportOptionList;
  getPriorEnquiry: GetPriorEnquiry;
  updatePriorEnquiry: UpdatePriorEnquiry;
  createPriorEnquiry: CreatePriorEnquiry;
  deletePriorEnquiry: DeletePriorEnquiry;
  getLine: GetLine;
  updateLine: UpdateLine;
  createLine: CreateLine;
  deleteLine: DeleteLine;
  getWrapUpLinesOrder: GetWrapUpLinesOrder;
  updateWrapUpLinesOrder: UpdateWrapUpLinesOrder;
  getLineTables: GetLineTables;
  getBranchTables: GetBranchTables;
  addTable: AddTable;
  deleteTable: DeleteTable;
  exportTable: ExportTable;
  getLineTests: GetLineTests;
  addLineTest: AddLineTest;
  importForEachTests: ImportForEachTests;
  deleteLineTest: DeleteLineTest;
  exportLineTest: ExportLineTest;
  runLineTest: RunLineTest;
  exportTestTemplate: ExportTestTemplate;
  validateBranch: ValidateBranch;
  validateLine: ValidateLine;
  typeaheadBucketValue: TypeAheadBucketValueCompleter;
  typeaheadContributionBucket: TypeAheadContributionBucketCompleter;
  editorConfiguration: EditorConfigurationLoader;
}
