import React from 'react';
import { createRoot } from 'react-dom/client';
import './App.less';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AuthenticationWrapper from './AuthenticationWrapper';
import { LocalizationProvider } from '../services/localization/localization.service';
import { MonitoringProvider } from '../services/monitoring/MonitoringProvider';
import { registerEnquiryMutations } from '../services/enquiry/enquiry.service';
import { registerPublicBranchCalls } from '../services/branches/branches.service';
import { registerUserCalls } from '../services/users/users.service';
import { registerEditorCalls } from '../services/editor/editor.service';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: Infinity,
    },
  },
});

registerEnquiryMutations(queryClient);
registerPublicBranchCalls(queryClient);
registerUserCalls(queryClient);
registerEditorCalls(queryClient);

createRoot(document.getElementById('app')!).render(
  <LocalizationProvider>
    <QueryClientProvider client={queryClient}>
      <MonitoringProvider>
        <AuthenticationWrapper />
      </MonitoringProvider>
    </QueryClientProvider>
  </LocalizationProvider>,
);
