export enum BranchesQueryKeys {
  PublicBranches = 'b_PublicBranches',
  PrivateBranches = 'b_PrivateBranches',
  PrivateBranchHistory = 'b_PrivateBranchHistory',
  BranchAssignees = 'b_BranchAssignees',
  PullSourceBranches = 'b_PullSourceBranches',
  BranchHistory = 'b_BranchHistory',
  JoiningCommitHistory = 'b_JoiningCommitHistory',
  CommitSummary = 'b_CommitSummary',
  DeploymentTags = 'b_DeploymentTags',
  DeploymentHistory = 'b_DeploymentHistory',
  LineTests = 'b_LineTests',
  MetadataList = 'b_MetadataList',
  LinkTags = 'b_LinkTags',

  CreatePublicBranch = 'b_CreatePublicBranch',
  ImportPublicBranch = 'b_ImportPublicBranch',
  DeletePublicBranch = 'b_DeletePublicBranch',
  CreatePrivateBranch = 'b_CreatePrivateBranch',
  AssignPrivateBranch = 'b_AssignPrivateBranch',
  PullBranch = 'b_PullBranch',
  PushBranch = 'b_PushBranch',
  ImportRules = 'b_ImportRules',
  ImportTranslation = 'b_ImportTranslation',
  DeletePrivateBranch = 'b_DeletePrivateBranch',
  CreateDeploymentTag = 'b_CreateDeploymentTag',
  SetDeploymentState = 'b_SetDeploymentState',
  RunLineTests = 'b_RunLineTests',
  ImportMetadata = 'b_ImportMetadata',
  SelectLinkTagMetadata = 'b_SelectLinkTagMetadata',
}
