import { RcFile } from 'antd/lib/upload';
import {
  QueryClient, useQuery,
} from '@tanstack/react-query';
import {
  // eslint-disable-next-line max-len
  CommitSummaryEntry,
  BranchCommit,
  DeploymentHistoryEntry,
  DeploymentTag,
  LineTestRun,
  PrivateBranch,
  LinkTag,
  PublicBranches,
  UsersList,
} from '../../dtos/branches.dto';
import {
  DataRequestParams,
  Paginated,
  QueryOptions,
  QueryResult,
  RequiredKey,
  useAPIMutation,
  useKnownMutation,
} from '../types';
import {
  // eslint-disable-next-line max-len
  AssignPrivateBranch,
  AssignPrivateBranchParams,
  CreateDeploymentTag,
  CreatePrivateBranch,
  CreatePublicBranch,
  DeletePrivateBranch,
  ExportBranchCommitParams,
  GetBranchHistoryParams,
  GetCommitSummaryParams,
  GetDeploymentHistoryParams,
  GetDeploymentTagsParams,
  GetJoiningCommitHistoryParams,
  GetLineTestsParams,
  GetLinkTagsParams,
  ImportMetadata,
  ImportPublicBranch,
  ImportTranslation,
  PullBranch,
  PushBranch,
  PushBranchParams,
  RunLineTest,
  SelectLinkTagMetadata,
  SelectLinkTagMetadataParams,
  SetDeploymentState,
} from './api/types';
import { getBranchesAPI } from './api';
import {
  clearEditorCaches,
} from '../editor/editor.service';
import { BranchesQueryKeys } from './branchesQueryKeys';
import { ShellQueryKeys } from '../enquiry/shellQueryKeys';
import { download } from '../../utils/download';

const API = getBranchesAPI();

export const usePublicBranches = (options?: QueryOptions<PublicBranches, BranchesQueryKeys[]>): QueryResult<PublicBranches> => useQuery(
  [BranchesQueryKeys.PublicBranches],
  () => API.getPublicBranches.call(),
  options,
);

export const useCreatePublicBranchMutation = useAPIMutation<CreatePublicBranch>(BranchesQueryKeys.CreatePublicBranch);
// eslint-disable-next-line max-len
const registerCreatePublicBranch = (queryClient: QueryClient) => queryClient.setMutationDefaults([BranchesQueryKeys.CreatePublicBranch], {
  mutationFn: API.createPublicBranch.call,
  onSuccess: () => {
    queryClient.invalidateQueries([BranchesQueryKeys.PublicBranches]);
  },
});

export const useImportPublicBranchMutation = useAPIMutation<ImportPublicBranch>(BranchesQueryKeys.ImportPublicBranch);
// eslint-disable-next-line max-len
const registerImportPublicBranch = (queryClient: QueryClient) => queryClient.setMutationDefaults([BranchesQueryKeys.ImportPublicBranch], {
  mutationFn: API.importPublicBranch.call,
  onSuccess: (_, variables: ImportRulesMutationVariables) => {
    const {
      branchName,
    } = variables;
    queryClient.invalidateQueries([BranchesQueryKeys.BranchHistory, branchName]);
    queryClient.invalidateQueries([BranchesQueryKeys.JoiningCommitHistory, branchName]);
  },
});

export const useDeletePublicBranchMutation = useKnownMutation<string>(BranchesQueryKeys.DeletePublicBranch);
// eslint-disable-next-line max-len
const registerDeletePublicBranch = (queryClient: QueryClient) => queryClient.setMutationDefaults([BranchesQueryKeys.DeletePublicBranch], {
  mutationFn: API.deletePublicBranch.call,
  onSuccess: () => {
    queryClient.invalidateQueries([BranchesQueryKeys.PublicBranches]);
  },
});

export const exportPublicBranch = download(API.exportPublicBranch.call);

export const exportReport = download(API.exportReport.call);

const registerGetPrivateBranches = (queryClient: QueryClient) => queryClient.setQueryDefaults([BranchesQueryKeys.PrivateBranches], {
  // eslint-disable-next-line max-len
  queryFn: async ({ queryKey: [, publicBranchName] }): Promise<Paginated<PrivateBranch>> => API.getPrivateBranches.call({ publicBranchName: publicBranchName as string })
    .then((value) => ({ items: value.branches, total: value.branches.length, limit: value.branches.length })),
});

// eslint-disable-next-line max-len
const registerGetPrivateBranchCommitSummary = (queryClient: QueryClient) => queryClient.setQueryDefaults([BranchesQueryKeys.PrivateBranchHistory], {
  queryFn: async ({ queryKey: [, branchName, params] }): Promise<Paginated<BranchCommit>> => API
    .getPrivateBranchesHistory.call({ branchName, params } as GetBranchHistoryParams),
});
export const usePrivateBranchCommitSummary = (
  branchName: RequiredKey,
  params: DataRequestParams = {},
  options?: QueryOptions<Paginated<BranchCommit>, [string, RequiredKey, DataRequestParams]>,
) => useQuery(
  [BranchesQueryKeys.PrivateBranchHistory, branchName, params],
  {
    enabled: !!branchName,
    ...options,
  },
);

export const exportPrivateBranch = download(API.exportPrivateBranch.call);

export const exportCommit = download(API.exportCommit.call);

export const useCreatePrivateBranchMutation = useAPIMutation<CreatePrivateBranch>(BranchesQueryKeys.CreatePrivateBranch);
// eslint-disable-next-line max-len
const registerCreatePrivateBranch = (queryClient: QueryClient) => queryClient.setMutationDefaults([BranchesQueryKeys.CreatePrivateBranch], {
  mutationFn: API.createPrivateBranch.call,
  onSuccess: () => {
    queryClient.invalidateQueries([BranchesQueryKeys.PrivateBranches]);
  },
});

export const useBranchAssignees = (options?: QueryOptions<UsersList, BranchesQueryKeys[]>): QueryResult<UsersList> => useQuery(
  [BranchesQueryKeys.BranchAssignees],
  async (): Promise<UsersList> => API.getBranchAssignees.call(),
  options,
);

export const useAssignPrivateBranchMutation = useAPIMutation<AssignPrivateBranch>(BranchesQueryKeys.AssignPrivateBranch);
// eslint-disable-next-line max-len
const registerAssignPrivateBranch = (queryClient: QueryClient) => queryClient.setMutationDefaults([BranchesQueryKeys.AssignPrivateBranch], {
  mutationFn: API.assignPrivateBranch.call,
  onSuccess: (_, variables: AssignPrivateBranchParams) => {
    const {
      publicBranchName,
    } = variables;
    queryClient.invalidateQueries([BranchesQueryKeys.PrivateBranches, publicBranchName]);
  },
});

export const usePullSourceBranches = (
  options?: QueryOptions<PublicBranches, BranchesQueryKeys[]>,
): QueryResult<PublicBranches> => useQuery(
  [BranchesQueryKeys.PullSourceBranches],
  async (): Promise<PublicBranches> => API.getPublicBranches.call(),
  options,
);

export const usePullBranchMutation = useAPIMutation<PullBranch>(BranchesQueryKeys.PullBranch);
const registerPullBranch = (queryClient: QueryClient) => queryClient.setMutationDefaults([BranchesQueryKeys.PullBranch], {
  mutationFn: API.pullBranch.call,
});

export const usePushBranchMutation = useAPIMutation<PushBranch>(BranchesQueryKeys.PushBranch);
const registerPushBranch = (queryClient: QueryClient) => queryClient.setMutationDefaults([BranchesQueryKeys.PushBranch], {
  mutationFn: API.pushBranch.call,
  onSuccess: (_, variables: PushBranchParams) => {
    const {
      toBranchName,
    } = variables;
    queryClient.invalidateQueries([BranchesQueryKeys.PrivateBranches, toBranchName]);
  },
});

export interface ImportRulesMutationVariables {
  branchName: string;
  file: RcFile;
}
export const useImportRulesMutation = useKnownMutation<ImportRulesMutationVariables>(BranchesQueryKeys.ImportRules);
const registerImportRules = (queryClient: QueryClient) => queryClient.setMutationDefaults([BranchesQueryKeys.ImportRules], {
  mutationFn: API.importRules.call,
  onSuccess: (_, variables: ImportRulesMutationVariables) => {
    const {
      branchName,
    } = variables;
    queryClient.invalidateQueries([BranchesQueryKeys.PrivateBranchHistory, branchName]);
    clearEditorCaches(queryClient, branchName);
  },
});

export const useImportTranslationMutation = useAPIMutation<ImportTranslation>(BranchesQueryKeys.ImportTranslation);
const registerImportTranslation = (queryClient: QueryClient) => queryClient.setMutationDefaults([BranchesQueryKeys.ImportTranslation], {
  mutationFn: API.importTranslation.call,
  onSuccess: (_, variables) => {
    const {
      branchName,
    } = variables;
    queryClient.invalidateQueries([BranchesQueryKeys.PrivateBranchHistory, branchName]);
    clearEditorCaches(queryClient, branchName);
  },
});

export const exportTranslations = download(API.exportTranslation.call);

export const useDeletePrivateBranchMutation = useAPIMutation<DeletePrivateBranch>(BranchesQueryKeys.DeletePrivateBranch);
// eslint-disable-next-line max-len
const registerDeletePrivateBranch = (queryClient: QueryClient) => queryClient.setMutationDefaults([BranchesQueryKeys.DeletePrivateBranch], {
  mutationFn: API.deletePrivateBranch.call,
  onSuccess: (_, variables) => {
    const {
      publicBranchName,
      privateBranchName,
    } = variables;
    queryClient.invalidateQueries([BranchesQueryKeys.PrivateBranches, publicBranchName]);
    clearEditorCaches(queryClient, privateBranchName);
  },
});

const registerGetBranchHistory = (queryClient: QueryClient) => queryClient.setQueryDefaults([BranchesQueryKeys.BranchHistory], {
  queryFn: async ({ queryKey: [, branchName, params] }): Promise<Paginated<BranchCommit>> => API
    .getBranchHistory.call({ branchName, params } as GetBranchHistoryParams),
});

// eslint-disable-next-line max-len
const registerGetJoiningCommitHistory = (queryClient: QueryClient) => queryClient.setQueryDefaults([BranchesQueryKeys.JoiningCommitHistory], {
  queryFn: async ({ queryKey: [, branchName, commitId, params] }): Promise<Paginated<BranchCommit>> => API
    .getJoiningCommitHistory.call({ branchName, commitId, params } as GetJoiningCommitHistoryParams),
});

export const useBranchHistory = (
  publicBranchId: RequiredKey,
  params: DataRequestParams = {},
  options?: QueryOptions<Paginated<BranchCommit>, [string, RequiredKey, DataRequestParams]>,
) => useQuery(
  [BranchesQueryKeys.BranchHistory, publicBranchId, params],
  {
    enabled: !!publicBranchId,
    ...options,
  },
);

export const exportBranchCommit = (publicBranchId: string, commitId: string) => API
  .exportBranchCommit.call({ publicBranchId, commitId } as ExportBranchCommitParams);

const registerGetCommitSummary = (queryClient: QueryClient) => queryClient.setQueryDefaults([BranchesQueryKeys.CommitSummary], {
  queryFn: async ({ queryKey: [, commitId, params] }): Promise<Paginated<CommitSummaryEntry>> => API
    .getCommitSummary.call({ commitId, params } as GetCommitSummaryParams),
});
export const useCommitSummary = (
  publicBranchId: RequiredKey,
  commitId: RequiredKey,
  params: DataRequestParams = {},
  options?: QueryOptions<Paginated<CommitSummaryEntry>, [string, RequiredKey, DataRequestParams]>,
) => useQuery(
  [BranchesQueryKeys.CommitSummary, commitId, params],
  {
    enabled: !!commitId,
    ...options,
  },
);

const registerGetDeploymentTags = (queryClient: QueryClient) => queryClient.setQueryDefaults([BranchesQueryKeys.DeploymentTags], {
  queryFn: async ({ queryKey: [, branchName, params] }): Promise<Paginated<DeploymentTag>> => API
    .getDeploymentTags.call({ branchName, params } as GetDeploymentTagsParams),
});
export const useDeploymentTags = (
  branchName: RequiredKey,
  params: DataRequestParams = {},
  options?: QueryOptions<Paginated<DeploymentTag>, [string, RequiredKey, DataRequestParams]>,
) => useQuery(
  [BranchesQueryKeys.DeploymentTags, branchName, params],
  {
    enabled: !!branchName,
    ...options,
  },
);

export const useCreateDeploymentTagMutation = useAPIMutation<CreateDeploymentTag>(BranchesQueryKeys.CreateDeploymentTag);
// eslint-disable-next-line max-len
const registerCreateDeploymentTag = (queryClient: QueryClient) => queryClient.setMutationDefaults([BranchesQueryKeys.CreateDeploymentTag], {
  mutationFn: API.createDeploymentTag.call,
  onSuccess: () => {
    queryClient.invalidateQueries([BranchesQueryKeys.DeploymentTags]);
    queryClient.invalidateQueries([ShellQueryKeys.Branches]);
  },
});

export const exportDeploymentTag = download(API.exportDeploymentTag.call);

const registerGetDeploymentHistory = (queryClient: QueryClient) => queryClient.setQueryDefaults([BranchesQueryKeys.DeploymentHistory], {
  queryFn: async ({ queryKey: [, publicBranchName, tagName, environmentName, params] }): Promise<Paginated<DeploymentHistoryEntry>> => API
    .getDeploymentHistory.call({
      publicBranchName, tagName, environmentName, params,
    } as GetDeploymentHistoryParams),
});
export const useDeploymentHistory = (
  publicBranchId: RequiredKey,
  tagName: RequiredKey,
  environmentName: RequiredKey,
  params: DataRequestParams = {},
  options?: QueryOptions<Paginated<DeploymentHistoryEntry>, [string, RequiredKey, RequiredKey, RequiredKey, DataRequestParams]>,
) => useQuery(
  [BranchesQueryKeys.DeploymentHistory, publicBranchId, tagName, environmentName, params],
  {
    enabled: !!publicBranchId && !!tagName && !!environmentName,
    ...options,
  },
);

export const useSetDeploymentStateMutation = useAPIMutation<SetDeploymentState>(BranchesQueryKeys.SetDeploymentState);
// eslint-disable-next-line max-len
const registerSetDeploymentState = (queryClient: QueryClient) => queryClient.setMutationDefaults([BranchesQueryKeys.SetDeploymentState], {
  mutationFn: API.setDeploymentState.call,
  onSuccess: (_, variables) => {
    const {
      publicBranchName,
      tagName,
      environmentName,
    } = variables;
    queryClient.invalidateQueries([BranchesQueryKeys.DeploymentHistory, publicBranchName, tagName, environmentName]).then(() => {
      queryClient.invalidateQueries([BranchesQueryKeys.DeploymentTags]);
    });
  },
});

const registerGetLineTests = (queryClient: QueryClient) => queryClient.setQueryDefaults([BranchesQueryKeys.LineTests], {
  queryFn: async ({ queryKey: [, publicBranchName, params] }): Promise<Paginated<LineTestRun>> => API
    .getLineTests.call({ publicBranchName, params } as GetLineTestsParams),
});
export const useLineTests = (
  publicBranchName: RequiredKey,
  params: DataRequestParams = {},
  options?: QueryOptions<Paginated<LineTestRun>, [string, RequiredKey, DataRequestParams]>,
) => useQuery(
  [BranchesQueryKeys.LineTests, publicBranchName, params],
  {
    enabled: !!publicBranchName,
    ...options,
  },
);

export const useRunLineTestsMutation = useAPIMutation<RunLineTest>(BranchesQueryKeys.RunLineTests);
const registerRunLineTests = (queryClient: QueryClient) => queryClient.setMutationDefaults([BranchesQueryKeys.RunLineTests], {
  mutationFn: API.runLineTest.call,
  onSuccess: (_, variables) => {
    const {
      publicBranchName,
    } = variables;
    queryClient.invalidateQueries([BranchesQueryKeys.LineTests, publicBranchName]);
  },
});

export const exportLineTests = download(API.exportLineTests.call, ({ privateBranchName }) => `${privateBranchName}-TestReport.xlsx`);

export const useImportMetadataMutation = useAPIMutation<ImportMetadata>(BranchesQueryKeys.ImportMetadata);
const registerImportMetadata = (queryClient: QueryClient) => queryClient.setMutationDefaults([BranchesQueryKeys.ImportMetadata], {
  mutationFn: API.importMetadata.call,
  onSuccess: () => {
    // TODO: Handle the results of an import
  },
});

export const exportMetadata = download(API.exportMetadata.call);

// eslint-disable-next-line max-len
export const useMetadataList = (publicBranchId: RequiredKey, options?: QueryOptions<string[], BranchesQueryKeys[]>): QueryResult<string[]> => useQuery(
  [BranchesQueryKeys.MetadataList],
  () => API.getMetadataList.call(publicBranchId as string),
  {
    enabled: !!publicBranchId,
    ...options,
  },
);

const registerGetLinkTags = (queryClient: QueryClient) => queryClient.setQueryDefaults([BranchesQueryKeys.LinkTags], {
  queryFn: async ({ queryKey: [, publicBranchId, params] }): Promise<Paginated<LinkTag>> => API
    .getLinkTags.call({ publicBranchId, params } as GetLinkTagsParams),
});
export const useLinkTags = (
  publicBranchId: RequiredKey,
  params: DataRequestParams = {},
  options?: QueryOptions<Paginated<LinkTag>, [string, RequiredKey, DataRequestParams]>,
) => useQuery(
  [BranchesQueryKeys.LinkTags, publicBranchId, params],
  {
    enabled: !!publicBranchId,
    ...options,
  },
);

export const useSelectLinkTagMetadataMutation = useAPIMutation<SelectLinkTagMetadata>(BranchesQueryKeys.SelectLinkTagMetadata);
// eslint-disable-next-line max-len
const registerSelectLinkTagMetadata = (queryClient: QueryClient) => queryClient.setMutationDefaults([BranchesQueryKeys.SelectLinkTagMetadata], {
  mutationFn: API.selectLinkTagMetadata.call,
  onSuccess: (data: LinkTag, variables: SelectLinkTagMetadataParams) => {
    const {
      publicBranchId,
      tag,
    } = variables;
    queryClient.setQueriesData([BranchesQueryKeys.LinkTags, publicBranchId], (queryData: any) => ({
      ...queryData,
      items: queryData.items.map((item: LinkTag) => (item.name === tag ? data : item)),
    }));
  },
});

export const registerPublicBranchCalls = (queryClient: QueryClient) => {
  const registrations = [
    registerCreatePublicBranch,
    registerImportPublicBranch,
    registerDeletePublicBranch,
    registerGetPrivateBranches,
    registerAssignPrivateBranch,
    registerPullBranch,
    registerPushBranch,
    registerImportRules,
    registerImportTranslation,
    registerDeletePrivateBranch,
    registerGetBranchHistory,
    registerGetJoiningCommitHistory,
    registerGetCommitSummary,
    registerGetDeploymentTags,
    registerGetDeploymentHistory,
    registerSetDeploymentState,
    registerCreateDeploymentTag,
    registerCreatePrivateBranch,
    registerGetLineTests,
    registerRunLineTests,
    registerGetPrivateBranchCommitSummary,
    registerImportMetadata,
    registerGetLinkTags,
    registerSelectLinkTagMetadata,
  ];
  registrations.forEach((register) => register(queryClient));
};
