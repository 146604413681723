import Cookies from 'js-cookie';
import { CookieService } from './types';

class JSCookieService implements CookieService {
  private cookies = Cookies;

  get(key: string): string | undefined {
    return this.cookies.get(key);
  }

  set(key: string, value: string, expiryDaysInFuture: number): void {
    this.cookies.set(key, value, { expires: expiryDaysInFuture, secure: true });
  }

  setWithSessionExpiry(key: string, value: string): void {
    this.cookies.set(key, value, { secure: true });
  }

  remove(key: string): void {
    this.cookies.remove(key);
  }

  getAll(): Record<string, string> {
    return this.cookies.get();
  }
}

export default JSCookieService;
