import { QueryOptions, useQuery } from '@tanstack/react-query';
import { QueryResult } from '../types';
import { getClientConfigurationAPI } from '../config';
import { ClientConfig } from '../../dtos/clientConfig.dto';

export const API = getClientConfigurationAPI();

export const useGetClientConfig = (options?: QueryOptions<ClientConfig, any>): QueryResult<ClientConfig> => useQuery(
  ['monitoring_ClientConfig'],
  async (): Promise<ClientConfig> => API.getClientConfig.call(),
  options,
);
