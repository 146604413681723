import { TrackingConsent } from '@datadog/browser-core';
import { RumPublicApi } from '@datadog/browser-rum-core';
import { ErrorInfo } from 'react';
import { MonitoringAttributes, MonitoringConnector } from './types';
import { TrackingPreferences } from '../cookie/types';

class DatadogConnector implements MonitoringConnector {
  private readonly datadogRum: RumPublicApi;

  constructor(datadogRum: RumPublicApi) {
    this.datadogRum = datadogRum;
  }

  init = async (monitoringAttributes?: MonitoringAttributes) => {
    if (monitoringAttributes) {
      this.datadogRum.init({
        applicationId: monitoringAttributes.applicationId,
        clientToken: monitoringAttributes.clientToken,
        site: monitoringAttributes.site,
        service: 'rules-editor-ui',
        version: process.env.BUILD_VERSION,
        env: monitoringAttributes.env,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        allowedTracingUrls: [(url) => url.includes('apiEde')],
        defaultPrivacyLevel: 'mask-user-input',
        trackingConsent: TrackingConsent.NOT_GRANTED,
      });
    }
  };

  addError(error: Error, info?: ErrorInfo): void {
    this.datadogRum.addError(error, info);
  }

  setUser(id: string, username: string) {
    this.datadogRum.setUser({ id, name: username });
  }

  setTrackingPreferences(trackingPreferences: TrackingPreferences): void {
    const trackingConsent = !trackingPreferences || !trackingPreferences.disableTracking
      ? TrackingConsent.GRANTED
      : TrackingConsent.NOT_GRANTED;

    this.datadogRum.setTrackingConsent(trackingConsent);
  }
}

export default DatadogConnector;
