import {
  useEffect, useState,
} from 'react';
import { AuthConfig, AuthConfigResponse } from './api/types';

const configurationUrl = '/apiEde/configurations/profileService';

export const useAuthConfig = (): AuthConfigResponse => {
  const [authConfig, setAuthConfig] = useState<AuthConfig>();
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    fetch(configurationUrl).then((response) => {
      if (response.status >= 400) {
        setError('Failed to load configuration');
        return {} as AuthConfig;
      } else {
        return response.json();
      }
    }).then((json) => {
      setLoaded(true);
      if (json) {
        setAuthConfig(json);
      }
    }).catch((configLoadError) => {
      setLoaded(true);
      setError(configLoadError);
    });
  }, []);

  return { authConfig, loaded, error } as AuthConfigResponse;
};
