import {
  QueryClient, useQuery, useQueryClient,
} from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import {
  Collation, Enquiry, EnquiryAnswers, NonDefEnquiry,
} from '../../dtos/enquiry.dto';
import {
  EnquiryResult, QueryOptions, RequiredKey, useKnownMutation,
} from '../types';
import { ShellQueryKeys } from './shellQueryKeys';
import { axiosAuthClient } from '../auth/axiosAuthClient';

export interface EnquiryParams {
  locale?: string;
  debug?: boolean;
  collation?: Collation;
}

export interface CloseEnquiryParams {
  username?: string;
  debug?: boolean;
  collation?: Collation;
  date?: Date;
  tryClose?: boolean;
}

interface EnquiryMutationVariables {
  username?: string;
  params: EnquiryParams;
}

export interface StartEnquiryMutationVariables extends EnquiryMutationVariables {
  env: string | undefined;
  branch: string;
  tag: string;
  answers?: EnquiryAnswers;
  linesAdjustment?: LinesAdjustments;
  priorEnquiries?: PriorEnquiryChannel[];
}

export interface PriorEnquiryChannel {
  channelName: string;
  enquiryId: string;
}

export interface LinesAdjustments {
  rootLines: {
    overrides?: LinesAdjustment[];
    additional?: LinesAdjustment[];
  }
  wrapUpLineOverrides: { name: string }[];
}

export interface LinesAdjustment {
  name: string;
  path: string;
  parentLines?: string[];
}

export interface AdvanceEnquiryMutationVariables extends EnquiryMutationVariables {
  id: string;
  env: string | undefined;
  answers: EnquiryAnswers;
}

export type CloseEnquiryMutationVariables = Omit<AdvanceEnquiryMutationVariables, 'answers'>;

export const defaultParams: EnquiryParams = {
  collation: 'BREADTH_FIRST_ORDER',
  debug: false,
};

const getBaseUrl = (env: RequiredKey): string => `/apiEde/engine/shell/${env}`;

const setEnquiryLocale = (queryClient: QueryClient, enquiry: Enquiry, params: EnquiryParams) => {
  if (!params.locale) {
    const newParams = { ...params, locale: enquiry.locale };
    queryClient.setQueryData([ShellQueryKeys.Enquiry, enquiry.enquiryId, newParams], enquiry);
  }
};

const buildEnquiryQueryKey = (environment : RequiredKey, enquiryId : RequiredKey, params? : EnquiryParams): any[] => {
  return [ShellQueryKeys.Enquiry, environment, enquiryId, params];
};

const clearEnquiryQueries = (queryClient: QueryClient, environment : RequiredKey, enquiryId : RequiredKey): void => {
  queryClient.removeQueries([ShellQueryKeys.Enquiry, environment, enquiryId]);
};

/** Retrieves the enquiry with the specified id.
 * @param params can be used to specify debug, collation or locale settings. Otherwise defaults will be used.
 *
 * Will not fetch any data until the **id** is defined.
*/
export const useEnquiry = (
  id: RequiredKey,
  env: RequiredKey,
  params?: EnquiryParams,
  options?: QueryOptions<Enquiry, (RequiredKey | EnquiryParams)[]>,
): EnquiryResult => {
  const queryClient = useQueryClient();
  const [resultingParams, setResultingParams] = useState({ ...defaultParams, ...params });

  useEffect(() => {
    setResultingParams({ ...defaultParams, ...params });
  }, [params]);

  return useQuery(
    buildEnquiryQueryKey(env, id, resultingParams),
    async (): Promise<Enquiry> => {
      const { data } = await axiosAuthClient.get<Enquiry>(`${getBaseUrl(env)}/enquiry/${id}`, { params: { ...resultingParams, embed: true } });
      return data;
    },
    {
      enabled: !!id && !!env,
      ...options,
      onSuccess: (data) => {
        setEnquiryLocale(queryClient, data, resultingParams);
        if (options?.onSuccess) {
          options.onSuccess(data);
        }
      },
    },
  );
};

/** Mutation for advancing enquiries with specific answers. */
export const useAdvanceEnquiryMutation = useKnownMutation<AdvanceEnquiryMutationVariables, Enquiry>(ShellQueryKeys.AdvanceEnquiry);
const registerAdvanceEnquiry = (queryClient: QueryClient) => queryClient.setMutationDefaults([ShellQueryKeys.AdvanceEnquiry], {
  mutationFn: async (variables: AdvanceEnquiryMutationVariables) => {
    const {
      answers, username, id, env,
    } = variables;
    const { locale, ...params } = { ...defaultParams, ...variables.params };
    const { data } = await axiosAuthClient.post<Enquiry>(`${getBaseUrl(env)}/enquiry/${id}`, { answers, locale, username }, { params: { ...params, embed: true } });
    return data;
  },
  onMutate: async (variables: AdvanceEnquiryMutationVariables) => {
    const {
      id, params, answers, env,
    } = variables;
    await queryClient.cancelQueries(buildEnquiryQueryKey(env, id));
    clearEnquiryQueries(queryClient, env, id);
    queryClient.setQueryData(buildEnquiryQueryKey(env, id, params), (enquiry: Enquiry | undefined) => {
      if (enquiry) {
        const newEnquiry = cloneDeep(enquiry);
        newEnquiry.allAnswers = { ...newEnquiry.allAnswers, ...answers };
        newEnquiry.sections.forEach((section) => section.enquiryLines.forEach((line) => line.questions.forEach((question) => {
          if (answers[question.name] !== undefined) {
            // eslint-disable-next-line no-param-reassign
            question.answers = answers[question.name];
          }
        })));
        return newEnquiry;
      }
      return enquiry as any;
    });
  },
  onSuccess: (result: Enquiry, variables: AdvanceEnquiryMutationVariables) => {
    const { id, env } = variables;
    const params = { ...defaultParams, ...variables.params };
    clearEnquiryQueries(queryClient, env, id);
    queryClient.setQueryData(buildEnquiryQueryKey(env, id, params), result);
    setEnquiryLocale(queryClient, result, params);
  },
  onError: (error, variables: AdvanceEnquiryMutationVariables) => {
    const { id, env } = variables;
    clearEnquiryQueries(queryClient, env, id);
    throw error;
  },
});

/** Mutation for starting enquiries. */
export const useStartEnquiryMutation = useKnownMutation<StartEnquiryMutationVariables, Enquiry>(ShellQueryKeys.StartEnquiry);
const registerStartEnquiry = (queryClient: QueryClient) => queryClient.setMutationDefaults([ShellQueryKeys.StartEnquiry], {
  mutationFn: async (variables: StartEnquiryMutationVariables) => {
    const {
      env, branch, tag, answers, username, params, linesAdjustment, priorEnquiries,
    } = variables;
    const { data } = await axiosAuthClient.post<Enquiry>(`${getBaseUrl(env)}/startEnquiry`, {
      answers, username, linesAdjustment, priorEnquiries,
    }, {
      params: {
        ...{ ...defaultParams, ...params }, branch, tag, embed: true,
      },
    });
    return data;
  },
  onSuccess: (result: Enquiry, variables: StartEnquiryMutationVariables) => {
    const params = { ...defaultParams, ...variables.params };
    clearEnquiryQueries(queryClient, variables.env, result.enquiryId);
    queryClient.setQueryData(buildEnquiryQueryKey(variables.env, result.enquiryId, params), result);
    setEnquiryLocale(queryClient, result, params);
  },
});

/** Mutation for closing enquiries */
export const useCloseEnquiryMutation = useKnownMutation<CloseEnquiryMutationVariables, NonDefEnquiry>(ShellQueryKeys.CloseEnquiry);
const registerCloseEnquiry = (queryClient: QueryClient) => queryClient.setMutationDefaults([ShellQueryKeys.CloseEnquiry], {
  mutationFn: async (variables: CloseEnquiryMutationVariables) => {
    const {
      username, id, env, params,
    } = variables;
    const { data } = await axiosAuthClient.post<NonDefEnquiry>(`${getBaseUrl(env)}/closeEnquiry/${id}`, { username }, { params: { ...{ ...defaultParams, ...params }, embed: false } });
    return data;
  },
  onSuccess: (result: NonDefEnquiry, variables: CloseEnquiryMutationVariables) => {
    const { id, env } = variables;
    clearEnquiryQueries(queryClient, env, id);
  },
});

export const registerEnquiryMutations = (queryClient: QueryClient) => {
  registerAdvanceEnquiry(queryClient);
  registerCloseEnquiry(queryClient);
  registerStartEnquiry(queryClient);
};
