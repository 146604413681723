import React, {createContext, useContext, useMemo} from 'react';
import { CookieContextData } from './types';
import JSCookieService from './JSCookieService';

const CookieContext = createContext<CookieContextData>(null!);

export const CookieProvider = ({ children } : { children: React.ReactNode }) => {
  const cookieService = new JSCookieService();
  const value = useMemo(() => ({ cookies: cookieService }), []);

  return (
    <CookieContext.Provider value={value}>
      {children}
    </CookieContext.Provider>
  );
};

export const useCookies = () => useContext(CookieContext);
