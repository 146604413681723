import { FC, useEffect } from 'react';
import { useMonitoring } from './MonitoringProvider';
import { useCookies } from '../cookie/CookieProvider';
import { getTrackingPreference } from '../cookie/getTrackingPreference';

const MonitoringLoader : FC = () => {
  const { connector } = useMonitoring();
  const { cookies } = useCookies();
  const preferences = getTrackingPreference(cookies);

  useEffect(() => {
    connector.setTrackingPreferences(preferences);
  }, []);

  return null;
};

export default MonitoringLoader;
