export enum Permission {
  CanActiveOwnProfile = 'CAN_ACTIVATE_OWN_PROFILE',
  // TODO - Deprecated, remove once profile service does not contain this permission
  CanAdministerUsers = 'CAN_ADMINISTER_USERS',
  CanAssignOwnershipOfLocalBranch = 'CAN_ASSIGN_OWNERSHIP_OF_LOCAL_BRANCH',
  CanChangePassword = 'CAN_CHANGE_PASSWORD',
  CanCreatePublicBranch = 'CAN_CREATE_PUBLIC_BRANCH',
  CanCreateTag = 'CAN_CREATE_TAG',
  CanCreateWorkBranch = 'CAN_CREATE_WORK_BRANCH',
  CanDeletePublicBranch = 'CAN_DELETE_PUBLIC_BRANCH',
  CanDeleteWorkBranch = 'CAN_DELETE_WORK_BRANCH',
  CanDeleteUser = 'CAN_DELETE_USER',
  CanDeployToNonProduction = 'CAN_DEPLOY_TO_NON_PRODUCTION',
  CanDeployToProduction = 'CAN_DEPLOY_TO_PRODUCTION',
  CanExportPublicBranch = 'CAN_EXPORT_PUBLIC_BRANCH',
  CanExportTag = 'CAN_EXPORT_TAG',
  CanExportTranslation = 'CAN_EXPORT_TRANSLATION',
  CanExportWorkBranch = 'CAN_EXPORT_WORK_BRANCH',
  CanForceComparableBranchImport = 'CAN_FORCE_COMPARABLE_BRANCH_IMPORT',
  CanForceRudimentalBranchImport = 'CAN_FORCE_RUDIMENTAL_BRANCH_IMPORT',
  CanForceStructuralBranchImport = 'CAN_FORCE_STRUCTURAL_BRANCH_IMPORT',
  CanForkPublicBranch = 'CAN_FORK_PUBLIC_BRANCH',
  CanGenerateEnquiryReportOnPublicBranch = 'CAN_GENERATE_ENQUIRY_REPORT_ON_PUBLIC_BRANCH',
  CanImportPublicBranch = 'CAN_IMPORT_PUBLIC_BRANCH',
  CanImportTranslation = 'CAN_IMPORT_TRANSLATION',
  CanImportWorkBranch = 'CAN_IMPORT_WORK_BRANCH',
  CanManageMiMetadata = 'CAN_MANAGE_MI_METADATA',
  CanPullFromNonParentBranch = 'CAN_PULL_FROM_NON_PARENT_BRANCH',
  CanPullFromParentBranch = 'CAN_PULL_FROM_PARENT_BRANCH',
  CanPushWorkBranch = 'CAN_PUSH_WORK_BRANCH',
  CanReadUser = 'CAN_READ_USER',
  CanResolveMergeConflicts = 'CAN_RESOLVE_MERGE_CONFLICTS',
  CanRunAllLineTests = 'CAN_RUN_ALL_LINE_TESTS',
  CanSeeUserGuide = 'CAN_SEE_USER_GUIDE',
  CanUpdateUser = 'CAN_UPDATE_USER',
  CanUseEditor = 'CAN_USE_EDITOR',
  CanUseEngineShell = 'CAN_USE_ENGINE_SHELL',
  CanUseEngineHistory = 'CAN_USE_ENGINE_HISTORY',
}

export const hasPermissions = (user: UserData | null) => (...permissions: Permission[]) => {
  if (user) {
    return permissions.some((permission: string) => user.permissions.some((userPermission: string) => userPermission === permission));
  }
  return false;
};

/**
 * User data that is extracted during Auth
 */
export interface UserData {
  id: string;
  username: string;
  languageTag: string;
  permissions: Permission[];
  providerIds: string[];
  allowAdminAccess: boolean;
  samlUser: boolean;
  internalUser: boolean;
}

/**
 * User domain used to drive User management pages
 */
export interface UserManagementData extends Omit<UserData, 'permissions' | 'providerIds' | 'allowAdminAccess' | 'samlUser' | 'internalUser'> {
  roles: string[]
}

export interface UserRole {
  name: string;
}

export interface UserLocale {
  id: string;
  name: string;
}
