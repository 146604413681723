import { AnalyticsBrowser } from '@segment/analytics-next';
import {
  MetricsCollector, UserAttributes,
} from './types';

class SegmentMetricsCollector implements MetricsCollector {
  private readonly analytics: AnalyticsBrowser;

  constructor(analytics: AnalyticsBrowser) {
    this.analytics = analytics;
  }

  onPageLoaded(category: string, name: string): void {
    this.analytics.page(category, name).then();
  }

  onUserIdentified(userId?: string, attributes?: UserAttributes): void {
    this.analytics.identify(userId, attributes).then();
  }

  onTrackEvent(event: string, attributes?: Record<string, string>): void {
    this.analytics.track(event, { ...attributes }).then();
  }
}

export default SegmentMetricsCollector;
