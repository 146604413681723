export const NavigationMetricEvents = {
  EditorButtonClick: 'Navigation Editor Button Clicked',
  ShellButtonClick: 'Navigation Shell Button Clicked',
  HistoryButtonClick: 'Navigation History Button Clicked',
  UserManagementButtonClick: 'Navigation User Management Button Clicked',
  UserGuideButtonClick: 'Navigation User Guide Button Clicked',
  ChangePasswordButtonClick: 'Navigation Change Password Button Clicked',
  LogoutButtonClick: 'Navigation Logout Button Clicked',
  LocaleSelectionChanged: 'Navigation Locale Selection Changed',
};
