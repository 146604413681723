import { SegmentKey } from "../../../dtos/config.dto";
import { axiosAuthClient } from "../../auth/axiosAuthClient";
import { APIRequest, addMocks } from "../../types";
import { SegmentAPI } from "./types";

const defaultUrl = '/apiEde';

export const getSegmentAPI = (baseUrl = defaultUrl) => {
    const api : SegmentAPI = {
        getSegmentKey: new APIRequest(
            () => axiosAuthClient.get<SegmentKey>(`${baseUrl}/configurations/segment`).then(({ data }) => data),
            { urlRegexp: '\\/api\\/configurations\\/segment', method: 'get' },
        ),
    } 

  return api;    
};