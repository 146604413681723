import { axiosAuthClient } from '../auth/axiosAuthClient';
import { APIRequest } from '../types';
import { ClientConfigurationAPI } from './types';
import { ClientConfig } from '../../dtos/clientConfig.dto';

const defaultUrl = '/apiEde';

export const getClientConfigurationAPI = (baseUrl = defaultUrl) => {
  const api : ClientConfigurationAPI = {
    getClientConfig: new APIRequest(() => axiosAuthClient
      .get<ClientConfig>(`${baseUrl}/configurations`)
      .then(({ data }) => data), { urlRegexp: '\\/api\\/configurations', method: 'get' }),
  };

  return api;
};
