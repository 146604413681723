export enum UserQueryKeys {
  UsersQKey = 'um_users',
  UserLocalesQKey = 'um_userLocales',
  UserRolesQKey = 'um_userRoles',

  CreateUserMKey = 'um_createUser',
  UpdateUserMKey = 'um_updateUser',
  ResetUserPasswordMKey = 'um_resetUserPassword',
  DeleteUserMKey = 'um_deleteUser',
}
