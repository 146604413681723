import saveAs from 'file-saver';
import { handleDownloadApiError } from './excel-api';
import { FileDownload } from '../services/types';

export const download = <Params>(
  request: (params: Params) => Promise<FileDownload>,
  nameBuilder?: (params: Params) => string,
) => (params: Params) => request(params)
    .then(({ blob, name }) => saveAs(blob, name || nameBuilder?.(params)))
    .catch(handleDownloadApiError);
