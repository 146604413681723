import { AnalyticsBrowser } from '@segment/analytics-next';
import { MetricsConfigurer } from './types';

class SegmentMetricsConfigurer implements MetricsConfigurer {
  private readonly analytics: AnalyticsBrowser;

  constructor(analytics: AnalyticsBrowser) {
    this.analytics = analytics;
  }

  load(key: string): void {
    this.analytics.load({ writeKey: key });
  }
}

export default SegmentMetricsConfigurer;
