import saveAs from 'file-saver';
import { APIErrorResponse } from '../services/types';
import { getFile } from './getFile';

/**
 * Handles a http error response by downloading the excel file (if response type is excel)
 * and mocking an JSON UME ApiError with the message code provided by default message.
 * (If UME ApiError it will be re-thrown), any other error types are re-thrown.
 * @param axiosError from an api call
 * @param errorCode used if excel file is downloaded
 */
export async function handleDownloadApiError(axiosError: any, errorCode : string = '') {
  if (axiosError) {
    const contentType = axiosError.response?.headers['content-type'];
    if (contentType && contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
      const { blob, name } = getFile(axiosError.response);
      saveAs(blob, name);
      // eslint-disable-next-line no-param-reassign
      axiosError.response.data = {
        correlationId: 'TODO',
        error: errorCode,
        message: 'Import/Export failed',
        validations: [],
      } as APIErrorResponse;
    } else if (contentType && contentType.includes('application/json')) {
      const text : string = await axiosError.response.data?.text();
      if (text) {
        try {
          // eslint-disable-next-line no-param-reassign
          axiosError.response.data = JSON.parse(text);
        } catch (e) {
          // rethrow error if it is not a valid JSON object
          // eslint-disable-next-line no-param-reassign
          axiosError.response.data = {
            correlationId: 'TODO',
            error: errorCode,
            message: (e as SyntaxError).message,
            validations: [],
          } as APIErrorResponse;
        }
      }
    }
    // always throw the error back up the chain
    throw axiosError;
  }
}
