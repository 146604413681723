import {
  QueryClient, useQuery,
} from '@tanstack/react-query';
import {
  UserLocale, UserManagementData, UserRole,
} from '../../dtos/user.dto';
import { UserQueryKeys } from './userQueryKeys';
import {
  DataRequestParams,
  Paginated,
  QueryOptions,
  QueryResult,
  useAPIMutation,
} from '../types';
import { getUsersAPI } from './api';
import {
  ResetUserPassword, CreateUser, DeleteUser, UpdateUser,
} from './api/types';

const API = getUsersAPI();

export const useUserLocales = (options?: QueryOptions<Paginated<UserLocale>, UserQueryKeys[]>): QueryResult<Paginated<UserLocale>> => useQuery(
  [UserQueryKeys.UserLocalesQKey],
  async (): Promise<Paginated<UserLocale>> => API.getUserLocales.call(),
  options,
);

export const useUserRoles = (options?: QueryOptions<Paginated<UserRole>, UserQueryKeys[]>): QueryResult<Paginated<UserRole>> => useQuery(
  [UserQueryKeys.UserRolesQKey],
  async (): Promise<Paginated<UserRole>> => API.getUserRoles.call(),
  options,
);

const registerGetUsers = (queryClient: QueryClient) => queryClient.setQueryDefaults([UserQueryKeys.UsersQKey], {
  queryFn: async ({ queryKey: [, params], pageParam }): Promise<Paginated<UserManagementData>> => API
    .getUsers.call({ ...(params as DataRequestParams), ...pageParam })
    .then((users) => ({ items: users, total: users.length, limit: users.length })),
});
export const useUsers = (
  params: DataRequestParams = {},
  options?: QueryOptions<Paginated<UserManagementData>, [string, DataRequestParams]>,
) => useQuery(
  [UserQueryKeys.UsersQKey, params],
  {
    ...options,
  },
);

export const useCreateUserMutation = useAPIMutation<CreateUser>(UserQueryKeys.CreateUserMKey);
const registerCreateUserMutation = (queryClient: QueryClient) => queryClient.setMutationDefaults([UserQueryKeys.CreateUserMKey], {
  mutationFn: API.createUser.call,
  onSuccess: () => {
    queryClient.invalidateQueries([UserQueryKeys.UsersQKey]);
  },
});

export const useUpdateUserMutation = useAPIMutation<UpdateUser>(UserQueryKeys.UpdateUserMKey);
const registerUpdateUserMutation = (queryClient: QueryClient) => queryClient.setMutationDefaults([UserQueryKeys.UpdateUserMKey], {
  mutationFn: API.updateUser.call,
  onSuccess: () => {
    queryClient.invalidateQueries([UserQueryKeys.UsersQKey]);
  },
});

export const useResetUserPasswordMutation = useAPIMutation<ResetUserPassword>(UserQueryKeys.ResetUserPasswordMKey);
const registerChangeUserPasswordMutation = (queryClient: QueryClient) => queryClient.setMutationDefaults([UserQueryKeys.ResetUserPasswordMKey], {
  mutationFn: API.resetUserPassword.call,
});

export const useDeleteUserMutation = useAPIMutation<DeleteUser>(UserQueryKeys.DeleteUserMKey);
const registerDeleteUserMutation = (queryClient: QueryClient) => queryClient.setMutationDefaults([UserQueryKeys.DeleteUserMKey], {
  mutationFn: API.deleteUser.call,
  onSuccess: () => {
    queryClient.invalidateQueries([UserQueryKeys.UsersQKey]);
  },
});

export const registerUserCalls = (queryClient: QueryClient) => {
  const registrations = [
    registerGetUsers,
    registerCreateUserMutation,
    registerUpdateUserMutation,
    registerChangeUserPasswordMutation,
    registerDeleteUserMutation,
  ];
  registrations.forEach((register) => register(queryClient));
};
