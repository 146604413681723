import { Button, Result, Space } from 'antd';
import { FrownOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface UnauthorizedPageProps {
  logoutHost: string;
  clientId: string;
}

/**
 * This page creates a link to logout so that users can switch users. We cannot use Auth0's logout as it is not
 * initialized yet. We need to construct the logout URL and redirect to it to complete the logout.
 * @param logoutHost Auth0 tenant host
 * @param clientId clientId of the rules-engine application
 * @constructor
 */
export const UnauthorizedPage: FC<UnauthorizedPageProps> = ({
  logoutHost = '',
  clientId = '',
}) => {
  const { t } = useTranslation();

  const returnTo = window.location.href.split('?')[0];

  return (
    <Result
      icon={<FrownOutlined />}
      title={t('unauthorised.page.text')}
      extra={(
        <Space wrap>
          <Button type="primary" onClick={() => window.location.assign(`https://${logoutHost}/v2/logout?client_id=${clientId}&returnTo=${returnTo}`)}>Logout</Button>
        </Space>
      )}
    />
  );
};
