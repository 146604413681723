export interface CookieService {
  get: (key: string) => string | undefined;
  set: (key: string, value: string, expiryDaysInFuture: number) => void;
  // This expires when the browser session ends (e.g closing window)
  setWithSessionExpiry: (key: string, value: string) => void;
  remove: (key: string) => void;
  getAll: () => Record<string, string>;
}

export interface CookieContextData {
  cookies: CookieService;
}

export enum CookieKeys {
  TACKING_PREFERENCES = 'tracking-preferences',
}

export interface TrackingPreferences {
  disableTracking: boolean;
}
