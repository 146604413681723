import React, {
  Component, createContext, ErrorInfo, FC, PropsWithChildren, ReactNode, useContext,
} from 'react';

export interface ErrorHandlerProps {
  /** Functional component or node to be rendered as a fallback when the app has crashed.
   * For a functional component the error is provided as a prop along with the 'removeErrorState' method to go back to the app.
   */
  errorFallback?: FC<{ error?: Error, removeErrorState?: VoidFunction }> | ReactNode;
  /** Custom error handler callback. Return 'true' to crash the app and display the fallback component. */
  onError?: (error: Error, info?: ErrorInfo) => boolean;
}

// eslint-disable-next-line no-console
const ErrorCatcherContext = createContext<(e: Error) => void>((e) => console.error(e));

const ErrorCatcher: FC<PropsWithChildren<{ handleError: (e: Error) => void }>> = ({ children, handleError }) => (
  <ErrorCatcherContext.Provider value={handleError}>{children}</ErrorCatcherContext.Provider>
);

/** A hook to retrieve a method that will redirect the error to the global handler */
export const useErrorCatcher = () => useContext(ErrorCatcherContext);

/** Custom error boundary to globally handle errors across the app. */
// eslint-disable-next-line max-len
export default class ErrorHandler extends Component<PropsWithChildren<ErrorHandlerProps>, { hasCrashed: boolean, error: Error | undefined }> {
  constructor(props: ErrorHandlerProps) {
    super(props);
    this.state = { hasCrashed: false, error: undefined };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.handleError(error, info);
  }

  handleError(error: Error, info?: ErrorInfo) {
    const { onError } = this.props;
    this.setState({ error });
    if (onError) {
      this.setState({ hasCrashed: onError(error, info) });
    } else {
      this.setState({ hasCrashed: true });
    }
  }

  handleNonRenderError = (e: Error) => this.handleError(e);

  render() {
    const { errorFallback, children } = this.props;
    const { hasCrashed, error } = this.state;

    if (hasCrashed) {
      if (errorFallback) {
        if (typeof errorFallback === 'function') {
          return errorFallback({ error, removeErrorState: () => this.setState({ hasCrashed: false }) });
        }
        return errorFallback;
      }
      return <h1>Oops, we crashed with no fallback, captain!</h1>;
    }

    return <ErrorCatcher handleError={this.handleNonRenderError}>{children}</ErrorCatcher>;
  }
}
