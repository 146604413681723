export enum EditorQueryKeys {
  BranchInfo = 'editor_BranchInfo',
  LinesList = 'editor_LinesList',
  BucketsList = 'editor_BucketsList',
  OptionListsList = 'editor_OptionListsList',
  QuestionsList = 'editor_QuestionsList',
  LocalesList = 'editor_LocalesList',
  PriorEnquiryChannelList = 'editor_PriorEnquiryChannelList',
  BucketDef = 'editor_BucketDef',
  LocaleDef = 'editor_LocaleDef',
  QuestionDef = 'editor_QuestionDef',
  OptionListDef = 'editor_OptionListDef',
  /**
     * @Deprecated should get the options and transform, here to support
     * a deprecated bucket drop down feature. (See query default handler).
     *
     * Key for client side paginated options belonging to an option list
     */
  OptionListOptionsDef = 'editor_OptionListOptionsDef',
  PriorEnquiryDef = 'editor_PriorEnquiryDef',
  LineDef = 'editor_LineDef',
  WrapUpLineOrderDef = 'editor_WrapUpLineOrderDef',
  GraphLineTables = 'editor_GraphLineTables',
  GraphBranchTables = 'editor_GraphBranchTables',
  GraphQuestions = 'editor_GraphQuestions',
  LineTests = 'editor_LineTests',
  ValidationErrors = 'editor_ValidationErrors',

  UpdateBucket = 'editor_UpdateBucket',
  CreateBucket = 'editor_CreateBucket',
  DeleteBucket = 'editor_DeleteBucket',
  UpdateLocale = 'editor_UpdateLocale',
  CreateLocale = 'editor_CreateLocale',
  DeleteLocale = 'editor_deleteLocale',
  UpdateQuestion = 'editor_UpdateQuestion',
  CreateQuestion = 'editor_CreateQuestion',
  DeleteQuestion = 'editor_DeleteQuestion',
  UpdateOptionList = 'editor_UpdateOptionList',
  CreateOptionList = 'editor_CreateOptionList',
  DeleteOptionList = 'editor_DeleteOptionList',
  ImportOptionList = 'editor_ImportOptionList',
  UpdatePriorEnquiry = 'editor_UpdatePriorEnquiry',
  CreatePriorEnquiry = 'editor_CreatePriorEnquiry',
  DeletePriorEnquiry = 'editor_DeletePriorEnquiry',
  UpdateLine = 'editor_UpdateLine',
  CreateLine = 'editor_CreateLine',
  DeleteLine = 'editor_DeleteLine',
  UpdateWrapUpLinesOrder = 'editor_UpdateWrapUpLinesOrder',
  CreateTable = 'editor_CreateTable',
  DeleteTable = 'editor_DeleteTable',
  CreateLineTest = 'editor_CreateLineTest',
  ImportForEachTests = 'editor_ImportForEachTests',
  DeleteLineTest = 'editor_DeleteLineTest',
  RunLineTest = 'editor_RunLineTest',
  ValidateBranch = 'editor_ValidateBranch',
  ValidateLine = 'editor_ValidateLine',
  TypeAheadBucketValue = 'editor_TypeAheadBucketValue',
  TypeAheadContributionBucket = 'editor_TypeAheadContributionBucket',
  EditorConfiguration = 'editor_configuration',
}
