export enum ShellQueryKeys {
  Environments = 'shell_environments',
  Branches = 'shell_branches',
  PriorEnquiryChannels = 'shell_priorEnquiryChannels',
  Lines = 'shell_lines',
  Enquiry = 'shell_Enquiry',
  QuestionOptionLookup = 'shell_QuestionOptionLookup',
  OptionList = 'shell_OptionList',

  AdvanceEnquiry = 'shell_AdvanceEnquiry',
  StartEnquiry = 'shell_StartEnquiry',
  CloseEnquiry = 'shell_CloseEnquiry',
}
