import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormModal, { FormModalState, useFormModalState } from '../../../components/form-modal/FormModal';
import { useResetUserPasswordMutation } from '../../../services/users/users.service';
import { ResetUserPasswordParams } from '../../../services/users/api/types';

const formLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

export type ResetPasswordModalState = FormModalState<{
  resetUserPasswordParams: ResetUserPasswordParams;
}>;

export interface ResetPasswordProps {
  onInitModal: (state: ResetPasswordModalState) => void;
  isSubmittingCallback? : (submitting : boolean) => void;
}

export const ResetPassword: FC<ResetPasswordProps> = ({
  onInitModal, isSubmittingCallback,
}) => {
  const { t } = useTranslation();
  const { mutateAsync: resetPassword } = useResetUserPasswordMutation();
  const modal = useFormModalState<{ resetUserPasswordParams: ResetUserPasswordParams }>({
    trackingPrefix: 'User Management Reset Password',
    onSubmit: ({ resetUserPasswordParams: { username: name } }) => resetPassword({ username: name }),
    onSuccess: (_, modalState) => modalState.close().success(t('resetpassword.modal.success')),
  });

  useEffect(() => {
    onInitModal(modal);
  }, []);

  useEffect(() => {
    isSubmittingCallback?.(modal.submitting);
  }, [modal.submitting]);

  return (
    <FormModal state={modal} title={t('resetpassword.modal.title')} formOverrides={formLayout} modalOverrides={{ closable: false, maskClosable: false }}>
      <p>{t('resetpassword.modal.text')}</p>
    </FormModal>
  );
};
