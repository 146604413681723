import { FC, useEffect } from 'react';
import { useGetSegmentKey } from './segment.service';
import { useMetrics } from './MetricsProvider';
import { useCookies } from '../cookie/CookieProvider';
import { getTrackingPreference } from '../cookie/getTrackingPreference';

const MetricsLoader : FC = () => {
  const { data: keyData } = useGetSegmentKey();
  const { configurer } = useMetrics();
  const { cookies } = useCookies();
  const preferences = getTrackingPreference(cookies);
  const trackingEnabled = () => !preferences || (preferences && !preferences.disableTracking);

  useEffect(() => {
    // If no preference is set assume tracking is enabled
    if (trackingEnabled()) {
      if (keyData) {
        configurer.load(keyData.writeKey);
      }
    }
  }, [keyData]);

  return null;
};

export default MetricsLoader;
