import { AnalyticsBrowser } from '@segment/analytics-next';
import React, {
  createContext, useContext,
} from 'react';
import { MetricsCollector, MetricsConfigurer } from './types';
import SegmentMetricsConfigurer from './SegmentMetricsConfigurer';
import SegmentMetricsCollector from './segmentMetricsCollector';

export interface MetricsContextData {
  metrics: MetricsCollector;
  configurer: MetricsConfigurer;
}

const MetricsContext = createContext<MetricsContextData>(null!);

export const MetricsProvider = ({ children } : { children: React.ReactNode }) => {
  const analytics = new AnalyticsBrowser();
  const segmentMetricsCollector = new SegmentMetricsCollector(analytics);
  const segmentMetricsConfigurer = new SegmentMetricsConfigurer(analytics);

  return (
    <MetricsContext.Provider value={{ metrics: segmentMetricsCollector, configurer: segmentMetricsConfigurer }}>
      {children}
    </MetricsContext.Provider>
  );
};

export const useMetrics = () => useContext(MetricsContext);
