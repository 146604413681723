import {
  // eslint-disable-next-line max-len
  BranchCommit,
  CommitSummaryEntry,
  DeploymentHistoryEntry,
  DeploymentTag,
  LineTestRun,
  LinkTag,
  PrivateBranch,
  PrivateBranches,
  PublicBranch,
  PublicBranches, UsersList,
} from '../../../dtos/branches.dto';
import { axiosAuthClient } from '../../auth/axiosAuthClient';
import {
  addMocks, APIRequest, formatRequestParams, Paginated,
} from '../../types';
import Mocks from './mocks';
import { BranchesAPI } from './types';
import { getFile } from '../../../utils/getFile';

const defaultUrl = '/apiEde';

export const getBranchesAPI = (baseUrl = defaultUrl) => {
  const api: BranchesAPI = {
    getPublicBranches: new APIRequest(
      () => axiosAuthClient.get<PublicBranches>(`${baseUrl}/branches?isPublic=true`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches', method: 'get' },
    ),
    createPublicBranch: new APIRequest(
      (params) => axiosAuthClient.post<PublicBranch>(`${baseUrl}/branches`, params).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches', method: 'post' },
    ),
    deletePublicBranch: new APIRequest(
      (branchName) => axiosAuthClient.delete(`${baseUrl}/branches/${encodeURI(branchName)}`).then((() => {})),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+', method: 'delete' },
    ),
    importPublicBranch: new APIRequest(
      (params) => {
        const formData = new FormData();
        formData.append('file', params.file);
        return axiosAuthClient.post(`${baseUrl}/branches/${params.branchName}/import`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      },
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/import', method: 'put' },
    ),
    exportPublicBranch: new APIRequest(
      (publicBranchName) => axiosAuthClient.get(`${baseUrl}/branches/${publicBranchName}/export`, { responseType: 'blob' }).then(getFile),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/export', method: 'get' },
    ),
    exportReport: new APIRequest(
      (branchName) => axiosAuthClient.get(`${baseUrl}/branches/${branchName}/enquiryReport`, { responseType: 'blob' }).then(getFile),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/enquiryReport', method: 'get' },
    ),
    getPrivateBranches: new APIRequest(
      (params) => axiosAuthClient.get<PrivateBranches>(`${baseUrl}/branches/${params.publicBranchName}/privateBranches`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/privateBranches', method: 'get' },
    ),
    createPrivateBranch: new APIRequest(
      (params) => axiosAuthClient.post<PrivateBranch>(`${baseUrl}/branches/${params.forkedFromBranchName}/privateBranches`, { name: params.name }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/privateBranches', method: 'post' },
    ),
    getBranchAssignees: new APIRequest(
      () => axiosAuthClient.get<UsersList>(`${baseUrl}/users/listByPermission?permission=CAN_CREATE_WORK_BRANCH&excludeCurrentUser=true`).then(({ data }) => data),
      { urlRegexp: '\\/api\\/users\\/listByPermission', method: 'get' },
    ),
    assignPrivateBranch: new APIRequest(
      (params) => axiosAuthClient.post(`${baseUrl}/branches/${params.branchName}/assign`, { username: params.username }),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/assign', method: 'post' },
    ),
    getPrivateBranchesHistory: new APIRequest(
      (params) => axiosAuthClient.get<Paginated<BranchCommit>>(`${baseUrl}/branches/${params.branchName}/history`, { params: formatRequestParams(params.params) }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/history', method: 'get' },
    ),
    exportPrivateBranch: new APIRequest(
      (privateBranchName) => axiosAuthClient.get(`${baseUrl}/branches/${privateBranchName}/export`, { responseType: 'blob' }).then(getFile),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/export', method: 'get' },
    ),
    pullBranch: new APIRequest(
      (params) => axiosAuthClient.post(`${baseUrl}/branches/${params.toBranchName}/pull`, { fromBranchName: params.fromBranchName, resolutions: params.conflictResolutions }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/pull', method: 'post' },
    ),
    pushBranch: new APIRequest(
      (params) => axiosAuthClient.post(`${baseUrl}/branches/${params.fromBranchName}/push`, { toBranchName: params.toBranchName }),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/push', method: 'post' },
    ),
    importRules: new APIRequest(
      (params) => {
        const formData = new FormData();
        formData.append('file', params.file);
        return axiosAuthClient.post(`${baseUrl}/branches/${params.branchName}/import`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      },
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/import', method: 'post' },
    ),
    importTranslation: new APIRequest(
      (params) => {
        const formData = new FormData();
        formData.append('file', params.file);
        return axiosAuthClient.post(`${baseUrl}/branches/${params.branchName}/import/translations`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => data);
      },
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/import/translations', method: 'post' },
    ),
    exportTranslation: new APIRequest(
      (params) => axiosAuthClient.get(`${baseUrl}/branches/${params.branchName}/export/translations`, { responseType: 'blob' }).then(getFile),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/export/translations', method: 'get' },
    ),
    deletePrivateBranch: new APIRequest(
      (params) => axiosAuthClient.delete(`${baseUrl}/branches/${encodeURI(params.privateBranchName)}`),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+', method: 'delete' },
    ),
    getBranchHistory: new APIRequest(
      (params) => axiosAuthClient.get<Paginated<BranchCommit>>(`${baseUrl}/branches/${params.branchName}/history`, { params: formatRequestParams(params.params) }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/history', method: 'get' },
    ),
    getJoiningCommitHistory: new APIRequest(
      (params) => axiosAuthClient.get<Paginated<BranchCommit>>(`${baseUrl}/branches/${params.branchName}/history/joining/${params.commitId}`, { params: formatRequestParams(params.params) }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/[a-zA-Z0-9\\-]+\\/history\\/joining\\/[a-zA-Z0-9\\-]+\\', method: 'get' },
    ),
    exportBranchCommit: new APIRequest(
      (params) => axiosAuthClient.get(`${baseUrl}/public-branches/${params.publicBranchId}/history/${params.commitId}`, { responseType: 'blob' }).then(getFile),
      { urlRegexp: '\\/api\\/public-branches\\/[a-zA-Z0-9\\-]+\\/history\\/[a-zA-Z0-9\\-]+\\', method: 'get' },
    ),
    getCommitSummary: new APIRequest(
      (params) => axiosAuthClient.get<Paginated<CommitSummaryEntry>>(`${baseUrl}/commits/${params.commitId}/summaries`, { params: formatRequestParams(params.params) }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/commits\\/[a-zA-Z0-9\\-]+\\/summaries', method: 'get' },
    ),
    getDeploymentTags: new APIRequest(
      (params) => axiosAuthClient.get<Paginated<DeploymentTag>>(`${baseUrl}/deployments`, { params: { branchName: params.branchName, offset: params.params.offset, limit: params.params.limit } }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/deployments', method: 'get' },
    ),
    createDeploymentTag: new APIRequest(
      (params) => axiosAuthClient.post<DeploymentTag>(`${baseUrl}/tags`, { name: params.name, branchName: params.branchName }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/tags', method: 'post' },
    ),
    exportDeploymentTag: new APIRequest(
      (params) => axiosAuthClient.get(`${baseUrl}/tags/${params.tagName}/export?branchName=${params.branchName}`, { responseType: 'blob' }).then(getFile),
      { urlRegexp: '\\/api\\/tags\\/[a-zA-Z0-9\\-]+\\/export', method: 'get' },
    ),
    getDeploymentHistory: new APIRequest(
      (params) => axiosAuthClient.get<Paginated<DeploymentHistoryEntry>>(`${baseUrl}/deployments/publicBranchName/${params.publicBranchName}/tags/${params.tagName}/environments/${params.environmentName}/history`, { params: formatRequestParams(params.params) }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/public-branches\\/[a-zA-Z0-9\\-]+\\/tags\\/[a-zA-Z0-9\\-]+\\/environments\\/[a-zA-Z0-9\\-]+\\/deployments', method: 'get' },
    ),
    setDeploymentState: new APIRequest(
      (params) => axiosAuthClient.post(`${baseUrl}/deployments`, params).then(({ data }) => data),
      { urlRegexp: '\\/api\\/deployments\\', method: 'post' },
    ),
    getLineTests: new APIRequest(
      (params) => axiosAuthClient.get<Paginated<LineTestRun>>(`${baseUrl}/branches/rule/lines/tests/summary?forkedFromBranchName=${params.publicBranchName}`, { params: formatRequestParams(params.params) }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/branches\\/rule\\/lines\\/tests\\/summary', method: 'get' },
    ),
    runLineTest: new APIRequest(
      (params) => axiosAuthClient.post(`${baseUrl}/branches/rule/lines/tests/run?branchName=${params.privateBranchName}`),
      { urlRegexp: '\\/api\\/branches\\/rule\\/lines\\/tests\\/run', method: 'post' },
    ),
    exportLineTests: new APIRequest(
      (params) => axiosAuthClient.get(`${baseUrl}/branches/rule/lines/tests/export?commitId=${params.commitId}&jobId=${params.jobId}`, { responseType: 'blob' }).then(getFile),
      { urlRegexp: '\\/api\\/branches\\/rule\\/lines\\/tests\\/export', method: 'get' },
    ),
    importMetadata: new APIRequest(
      (params) => {
        const formData = new FormData();
        formData.append('data', params.file);
        return axiosAuthClient.put(`${baseUrl}/public-branches/${params.publicBranchId}/metadata`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      },
      { urlRegexp: '\\/api\\/public-branches\\/[a-zA-Z0-9\\-]+\\/metadata', method: 'put' },
    ),
    exportMetadata: new APIRequest(
      (publicBranchId) => axiosAuthClient.get(`${baseUrl}/public-branches/${publicBranchId}/metadata`, { responseType: 'blob' }).then(getFile),
      { urlRegexp: '\\/api\\/public-branches\\/[a-zA-Z0-9\\-]+\\/metadata', method: 'get' },
    ),
    getMetadataList: new APIRequest(
      (publicBranchId) => axiosAuthClient.get(`${baseUrl}/public-branches/${publicBranchId}/metadata/list`),
      { urlRegexp: '\\/api\\/public-branches\\/[a-zA-Z0-9\\-]+\\/metadata/list', method: 'get' },
    ),
    getLinkTags: new APIRequest(
      (params) => axiosAuthClient.get<Paginated<LinkTag>>(`${baseUrl}/public-branches/${params.publicBranchId}/linkTags`, { params: formatRequestParams(params.params) }).then(({ data }) => data),
      { urlRegexp: '\\/api\\/public-branches\\/[a-zA-Z0-9\\-]+\\/linkTags', method: 'get' },
    ),
    selectLinkTagMetadata: new APIRequest(
      (params) => axiosAuthClient.put(`${baseUrl}/public-branches/${params.publicBranchId}/linkTags/${params.tag}`, params.selectedMetadata).then(({ data }) => data),
      { urlRegexp: '\\/api\\/public-branches\\/[a-zA-Z0-9\\-]+\\/linkTags\\/[a-zA-Z0-9\\-]+', method: 'put' },
    ),

    exportCommit: new APIRequest(
      (commitId) => axiosAuthClient.get(`${baseUrl}/commits/${commitId}/export`, { responseType: 'blob' }).then(getFile),
      { urlRegexp: '\\/api\\/commits\\/[a-zA-Z0-9\\-]+\\/export', method: 'get' },
    ),
  };

  

  return api;
};
