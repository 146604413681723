import React, { FC } from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import { useAuthConfig } from '../services/auth/auth.properties.service';
import Loader from '../components/loader/Loader';
import { ErrorPage } from '../components/error-page/ErrorPage';
import { UnauthorizedPage } from '../components/error-page/UnauthorizedPage';

const AuthenticationWrapper: FC = (): React.ReactElement => {
  const onRedirectCallback = (appState: AppState | undefined) => {
    window.history.replaceState({}, document.title, appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  };
  const authConfigResponse = useAuthConfig();

  if (!authConfigResponse.loaded) {
    return (
      <Loader />
    );
  } else if (authConfigResponse.error) {
    return (
      <ErrorPage />
    );
  }

  // When user authentication is successful but authorization fails for some reason, Auth0 uses the callback to the application
  // attaching 'error' and 'error_description' query parameters. By checking these, we can tell that it was an Auth0
  // authorization failure.
  const AUTH0_ERROR_QUERY_PARAMETER_NAME = 'error';
  const AUTH0_ERROR_DESCRIPTION_QUERY_PARAMETER_NAME = 'error_description';

  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has(AUTH0_ERROR_QUERY_PARAMETER_NAME) && searchParams.has(AUTH0_ERROR_DESCRIPTION_QUERY_PARAMETER_NAME)) {
    return (
      <UnauthorizedPage logoutHost={authConfigResponse.authConfig.auth0Domain} clientId={authConfigResponse.authConfig.clientId} />
    );
  } else {
    return (
      <Auth0Provider
        domain={authConfigResponse.authConfig?.auth0Domain as string}
        clientId={authConfigResponse.authConfig?.clientId as string}
        redirectUri={(typeof window !== 'undefined' && window.location.origin) || 'not-authenticated'}
        audience="https://rules-editor-service-api.underwriteme.co.uk"
        scope="openid email offline_access"
        useRefreshTokens
        cacheLocation="localstorage"
        onRedirectCallback={onRedirectCallback}
      >
        <App />
      </Auth0Provider>
    );
  }
};

export default AuthenticationWrapper;
