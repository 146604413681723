import {
  UserLocale, UserManagementData, UserRole,
} from '../../../dtos/user.dto';
import {
  addMocks, APIRequest, formatRequestParams, Paginated,
} from '../../types';
import Mocks from './mocks';
import { UsersAPI } from './types';
import { axiosAuthClient } from '../../auth/axiosAuthClient';
import { mapToUpdateUserRequest } from './mappers';

const defaultUrl = '/apiEde';

export const getUsersAPI = (baseUrl = defaultUrl) => {
  const api: UsersAPI = {
    getUserLocales: new APIRequest(
      () => axiosAuthClient.get<Paginated<UserLocale>>(`${baseUrl}/locales`).then(({ data }) => data),
      { urlRegexp: '\\/apiEde\\/locales', method: 'get' },
    ),
    getUserRoles: new APIRequest(
      () => axiosAuthClient.get<Paginated<UserRole>>(`${baseUrl}/roles?limit=100`).then(({ data }) => data),
      { urlRegexp: '\\/apiEde\\/roles', method: 'get' },
    ),
    getUsers: new APIRequest(
      (params) => axiosAuthClient.get<UserManagementData[]>(`${baseUrl}/users`, { params: formatRequestParams(params) }).then(({ data }) => data),
      { urlRegexp: '\\/apiEde\\/users', method: 'get' },
    ),
    createUser: new APIRequest(
      (params) => axiosAuthClient.post<UserManagementData>(`${baseUrl}/users`, params).then(({ data }) => data),
      { urlRegexp: '\\/apiEde\\/users', method: 'post' },
    ),
    updateUser: new APIRequest(
      (params) => axiosAuthClient.put<UserManagementData>(`${baseUrl}/users/${params.currentUsername}`, mapToUpdateUserRequest(params)).then(({ data }) => data),
      { urlRegexp: '\\/apiEde\\/users\\/[a-zA-Z0-9\\-]+', method: 'put' },
    ),
    resetUserPassword: new APIRequest(
      (params) => axiosAuthClient.put(`${baseUrl}/users/${params.username}/password`),
      { urlRegexp: '\\/apiEde\\/users\\/[a-zA-Z0-9\\-]+\\/password', method: 'put' },
    ),
    deleteUser: new APIRequest(
      (username) => axiosAuthClient.delete(`${baseUrl}/users/${username}`),
      { urlRegexp: '\\/apiEde\\/users\\/[a-zA-Z0-9\\-]+', method: 'delete' },
    ),
  };

  

  return api;
};
