import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  QueryObserverResult, QueryKey, UseQueryOptions, UseMutationOptions, UseMutationResult,
  useMutation, useInfiniteQuery, UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { Enquiry } from '../dtos/enquiry.dto';

export interface DataRequestParams {
  currentPage?: number;
  offset?: number;
  limit?: number;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  filters?: DataRequestFilter[];
}

export interface DataRequestFilter {
  field: string;
  operation: string;
  value: string;
  isNonExclusive?: boolean;
}

export const formatRequestParams = (params: DataRequestParams): Record<string, string | number | undefined> => {
  const {
    offset, limit, sortField, filters,
  } = params;
  const queryParams: Record<string, string | number | undefined> = { offset, limit };
  if (sortField) {
    queryParams.sort = `${params.sortOrder === 'asc' ? 'asc_' : 'desc_'}${params.sortField}`;
  }
  if (filters) {
    // eslint-disable-next-line no-nested-ternary
    queryParams.filter = filters.reduce((query, filter, index) => `${query}${index ? filter.isNonExclusive ? ' or ' : ' and ' : ''}${filter.field} ${filter.operation} ${filter.value}`, '');
  }
  return queryParams;
};

export interface Paginated<Data> {
  items: Data[];
  total: number;
  limit: number;
}

export type APIErrorResponse = {
  error: string;
  correlationId: string;
  message: string;
  validations?: APIErrorValidation[];
};

export type APIErrorValidation = {
  target: string;
  message: string;
  attributes?: Object;
};

export type APIError = AxiosError<APIErrorResponse>;

export const isAPIError = (error: Error): error is APIError => axios.isAxiosError(error) && error.response?.data.correlationId;

export class APIRequest<Output, Input = void> {
  constructor(
    private implementation: (input: Input) => Promise<Output>,
    public meta: {
      urlRegexp: string,
      method: string;
    },
  ) { }

  private devMock: ((input: Input) => Promise<Output>) | undefined = undefined;

  public call = (input: Input) => {
    if (this.devMock) {
      const mock = this.devMock;
      return new Promise<Output>((resolve) => { setTimeout(() => resolve(mock(input)), 200); });
    }
    return this.implementation(input);
  };
}

export type APIRequestInput<Request extends APIRequest<any, any>> = Request extends APIRequest<any, infer Input> ? Input : never;
export type APIRequestOutput<Request extends APIRequest<any, any>> = Request extends APIRequest<infer Output, any> ? Output : never;

export interface API {
  [key: string]: APIRequest<any, any>;
}

export type APIMocks<A extends API> = {
  [R in keyof A]: (data: APIRequestInput<A[R]>) => APIRequestOutput<A[R]>;
};

/**
 * Add mocks for all APIs.
 *
 * Can ignore a set selection of them if needed by providing an array of named endpoints to ignore.
 */
export const addMocks = <A extends API>(api: A, mocks: APIMocks<A>, ignore: string[] = []) => {
  // eslint-disable-next-line no-param-reassign
  Object.entries(mocks).forEach(([name, mock]) => {
    if (!ignore.includes(name)) {
      (api[name as any] as any).devMock = mock;
    }
  });
};

export type QueryError<T> = AxiosError<T> | Error;

export type QueryResult<T> = QueryObserverResult<T, QueryError<T>>;

export type EnquiryError = QueryError<Enquiry>;

export type EnquiryResult = QueryObserverResult<Enquiry, EnquiryError>;

export type QueryOptions<T, K extends QueryKey = RequiredKey[]> = Omit<UseQueryOptions<T, QueryError<T>, T, K>, 'queryKey' | 'queryFn'>;

export type InfiniteQueryOptions<T, K extends QueryKey = RequiredKey[]> = Omit<UseInfiniteQueryOptions<Paginated<T>, QueryError<Paginated<T>>, Paginated<T>, Paginated<T>, K>, 'queryKey' | 'queryFn'>;

export type MutationOptions<T, V> = Omit<UseMutationOptions<T, QueryError<T>, V>, 'mutationKey'>;

export type RequiredKey = string | undefined;

export type KnownMutation<Variables, Response = void> = UseMutationResult<Response, QueryError<Response>, Variables>;

export const useKnownMutation = <
  Variables,
  Response = void,
>(key: string):
  (options?: MutationOptions<Response, Variables>) => KnownMutation<Variables, Response> => (
    (options?: MutationOptions<Response, Variables>) => useMutation<Response, QueryError<Response>, Variables>([key], options)
  );

// eslint-disable-next-line max-len
export const useAPIMutation = <Request extends APIRequest<any, any>>(key: string) => useKnownMutation<APIRequestInput<Request>, APIRequestOutput<Request>>(key);

export const useInfiniteFetch = <Data>(
  queryKey: QueryKey,
  params: DataRequestParams = { offset: 0, limit: 20 },
  options?: InfiniteQueryOptions<Data, QueryKey>,
) => {
  const key = Array.isArray(queryKey) ? [...queryKey, params] : [queryKey, params];
  const queryResult = useInfiniteQuery<Paginated<Data>, QueryError<Paginated<Data>>>(key, {
    getNextPageParam: (lastPage, allPages) => {
      const totalOffset = allPages.length * lastPage.limit;
      if (totalOffset < lastPage.total) {
        return { offset: totalOffset };
      }
      return undefined;
    },
    ...options,
  });
  return queryResult;
};

export interface FileDownload {
  blob: Blob;
  name?: string;
}
