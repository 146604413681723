/* eslint-disable react/jsx-props-no-spreading */
import { LoadingOutlined } from '@ant-design/icons';
import {
  Layout as AntLayout, Spin,
} from 'antd';
import React, {
  CSSProperties, FC, ReactElement, useEffect, useRef, useState,
} from 'react';

import './Layout.less';
import { throttle } from 'lodash';

export interface LayoutProps {
  className?: string;
  style?: CSSProperties;
  hasContent?: boolean;
  leftSiderWidth?: number;
  maxLeftSiderWidth?: number;
  leftSiderCollpasedWidth?: number;
  leftSiderStyle?: CSSProperties;
  leftSiderClassName?: string;
  leftSiderCollpasedDefault?: boolean;
  contentStyle?: CSSProperties;
  contentClassName?: string;
  contentPadding?: number;
  rightSiderWidth?: number;
  rightSiderCollpasedWidth?: number;
  rightSiderStyle?: CSSProperties;
  rightSiderClassName?: string;
  rightSiderCollpasedDefault?: boolean;
  showLoader?: boolean;
  isLoading?: boolean;
  children: ReactElement[];
  subLayout?: boolean;
  onRightSiderCollapsed?: (isCollapsed: boolean) => void;
  onLeftSiderCollapsed?: (isCollapsed: boolean) => void;
  /**
   * To be used to reference the left side layout div
   */
  leftSideLayoutRef?: React.MutableRefObject<HTMLDivElement | null>
}

const Layout: FC<LayoutProps> = ({
  className,
  style,
  hasContent = true,
  leftSiderWidth = 300,
  maxLeftSiderWidth = 700,
  leftSiderCollpasedWidth = 80,
  leftSiderStyle,
  leftSiderClassName,
  leftSiderCollpasedDefault,
  contentStyle,
  contentClassName,
  contentPadding = 20,
  children,
  rightSiderWidth = 400,
  rightSiderCollpasedWidth = 80,
  rightSiderStyle,
  rightSiderClassName,
  rightSiderCollpasedDefault,
  showLoader,
  isLoading,
  subLayout,
  leftSideLayoutRef,
  onRightSiderCollapsed,
  onLeftSiderCollapsed,
}) => {
  const [leftSiderCollpased, setLeftSiderCollapsed] = useState<boolean>(!!leftSiderCollpasedDefault);
  const [rightSiderCollpased, setRightSiderCollapsed] = useState<boolean>(!!rightSiderCollpasedDefault);
  const [computedContentStyle, setComputedContentStyle] = useState<CSSProperties>({ ...contentStyle });
  const [computedLeftSiderStyle, setComputedLeftSiderStyle] = useState<CSSProperties>({ ...leftSiderStyle });
  const [computedRightSiderStyle, setComputedRightSiderStyle] = useState<CSSProperties>({ ...rightSiderStyle });
  const headerRef = useRef<HTMLDivElement>(null);
  const leftSiderRef = useRef<HTMLDivElement>(null);
  const rightSiderRef = useRef<HTMLDivElement>(null);

  const Header = children.find((el) => el.key === 'header');
  const NoContent = children.find((el) => el.key === 'no-content');
  const LeftSider = children.find((el) => el.key === 'left');
  const Content = children.find((el) => el.key === 'content');
  const RightSider = children.find((el) => el.key === 'right');

  const [currentLeftSiderWidth, setCurrentLeftSiderWidth] = useState(leftSiderWidth); // Initial width of the Sider
  const [isResizingSider, setIsResizingSider] = useState(false);
  const [siderHeight, setSiderHeight] = useState(0);

  const startResizingSider = (e: any) => {
    e.preventDefault();
    setIsResizingSider(true);
  };

  const stopResizingSider = () => {
    setIsResizingSider(false);
  };

  // Will smooth out the resize event
  const hasMouseMovedSignificantly = (e: any) => Math.abs(currentLeftSiderWidth - e.clientX) > 30;

  const isResizable = (e : any) => isResizingSider
      && hasMouseMovedSignificantly(e)
      && ((currentLeftSiderWidth > leftSiderCollpasedWidth && currentLeftSiderWidth < maxLeftSiderWidth)
          && (e.clientX > leftSiderCollpasedWidth && e.clientX < maxLeftSiderWidth));

  const resizeLeftSider = (e: any) => {
    if (isResizable(e)) {
      setCurrentLeftSiderWidth(e.clientX);
    }
  };

  // 20 ms wait gives a smooth resize
  const throttleResize = throttle(resizeLeftSider, 20);

  useEffect(() => {
    setComputedContentStyle({
      ...contentStyle,
      marginLeft: LeftSider ? (leftSiderCollpased ? leftSiderCollpasedWidth : currentLeftSiderWidth) + contentPadding : 0,
      marginRight: RightSider ? (rightSiderCollpased ? rightSiderCollpasedWidth : rightSiderWidth) + contentPadding : 0,
    });
    let siderTopOffset = headerRef.current?.offsetHeight || 0;
    if (subLayout) {
      siderTopOffset += 50;
    }
    const siderStyle = {
      marginTop: siderTopOffset,
      height: `calc(100vh - 48px - ${siderTopOffset}px)`,
    };
    setComputedLeftSiderStyle({
      ...leftSiderStyle,
      ...siderStyle,
    });
    setComputedRightSiderStyle({
      ...rightSiderStyle,
      ...siderStyle,
    });
    setSiderHeight(leftSiderRef?.current?.offsetHeight || 0);
    if (leftSideLayoutRef) {
      // eslint-disable-next-line no-param-reassign
      leftSideLayoutRef.current = leftSiderRef?.current;
    }
  }, [
    contentStyle,
    contentPadding,
    leftSiderCollpased,
    leftSiderCollpasedWidth,
    currentLeftSiderWidth,
    rightSiderCollpased,
    rightSiderCollpasedWidth,
    rightSiderWidth,
    children,
    headerRef.current?.offsetHeight,
  ]);

  const HeaderContent = (
    <div className={subLayout ? 'uw-sub-header' : 'uw-header-content'} ref={headerRef}>
      {Header}
      {showLoader && (
      <div className="uw-header-loader">
        <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      </div>
      )}
    </div>
  );

  const rightSiderCollapsed = (isCollapsed: boolean) => {
    setRightSiderCollapsed(isCollapsed);
    onRightSiderCollapsed?.(isCollapsed);
  };

  const leftSiderCollapsed = (isCollapsed: boolean) => {
    setLeftSiderCollapsed(isCollapsed);
    onLeftSiderCollapsed?.(isCollapsed);
  };

  const onMouseMove = (e: any) => {
    throttleResize(e);
  };

  return (
    <AntLayout
      className={`uw-layout ${className}`}
      style={style}
      {...(isResizingSider && { onMouseMove })}
      onMouseUp={stopResizingSider}
    >
      {!subLayout && (
      <AntLayout.Header>
        <img alt="logo" src="/assets/logo-dark.png" />
        {HeaderContent}
      </AntLayout.Header>
      )}
      <AntLayout.Content style={{ marginTop: headerRef.current?.offsetHeight }}>
        {subLayout && Header && HeaderContent}
        {!hasContent && NoContent}
        {hasContent && (
        <AntLayout>
          {LeftSider && (
          <AntLayout.Sider
            ref={leftSiderRef}
            id="uw-layout-left-sider"
            aria-label="left_sider"
            style={computedLeftSiderStyle}
            className={leftSiderClassName}
            width={currentLeftSiderWidth}
            collapsedWidth={leftSiderCollpasedWidth}
            onCollapse={leftSiderCollapsed}
            collapsible
          >
            {React.cloneElement(LeftSider, {
              collapsed: leftSiderCollpased,
              height: leftSiderRef.current?.offsetHeight,
            })}
          </AntLayout.Sider>
          )}
          {LeftSider && (
            <div
              className="resizer-container"
              role="button"
              onMouseDown={startResizingSider}
              style={{
                position: 'absolute',
                width: '10px',
                height: `${siderHeight - 30}px`,
                cursor: 'ew-resize',
                top: '80px',
                left: `${currentLeftSiderWidth}px`,
                zIndex: '2', // ensure it's above the resizer
              }}
              tabIndex={0}
              aria-label="resize_panel"
            >
              <div className="resizer-visual" />
            </div>
          )}
          <AntLayout.Content
            id={subLayout ? 'uw-sub-layout-content' : 'uw-layout-content'}
            style={computedContentStyle}
            className={contentClassName}
          >
            {Content}
          </AntLayout.Content>
          {RightSider && (
          <AntLayout.Sider
            ref={rightSiderRef}
            id="uw-layout-right-sider"
            style={computedRightSiderStyle}
            className={rightSiderClassName}
            width={rightSiderWidth}
            collapsedWidth={rightSiderCollpasedWidth}
            onCollapse={rightSiderCollapsed}
            collapsible
            reverseArrow
          >
            {React.cloneElement(RightSider, {
              collapsed: rightSiderCollpased,
              height: rightSiderRef.current?.offsetHeight,
            })}
          </AntLayout.Sider>
          )}
        </AntLayout>
        )}
      </AntLayout.Content>
    </AntLayout>
  );
};

export default Layout;
