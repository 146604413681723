import React, {
  createContext, useContext, useEffect,
} from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { MonitoringContextData } from './types';
import DatadogConnector from './DatadogConnector';
import { useGetClientConfig } from './monitoring.service';

const MonitoringContext = createContext<MonitoringContextData>(null!);

export const MonitoringProvider = ({ children } : { children: React.ReactNode }) => {
  const { data: config } = useGetClientConfig();
  const monitoringConnector = new DatadogConnector(datadogRum);

  useEffect(() => {
    if (config && config.datadog && config.datadog.enabled) {
      monitoringConnector.init({
        applicationId: config.datadog.applicationId,
        clientToken: config.datadog.clientToken,
        site: config.datadog.site,
        env: config.datadog.env,
      });
    }
  }, [config]);

  return (
    <MonitoringContext.Provider value={{ connector: monitoringConnector }}>
      {children}
    </MonitoringContext.Provider>
  );
};

export const useMonitoring = () => useContext(MonitoringContext);
