import {
  UpdateUserParams, UpdateUserRequest,
} from './types';

export function mapToUpdateUserRequest(params: UpdateUserParams) : UpdateUserRequest {
  return {
    languageTag: params.languageTag,
    roles: params.roles,
  };
}
