import { QueryOptions, useQuery } from '@tanstack/react-query';
import { SegmentKey } from '../../dtos/config.dto';
import { getSegmentAPI } from './api';
import { SegmentQueryKeys } from './segmentQueryKeys';
import { QueryResult } from '../types';

export const API = getSegmentAPI();

export const useGetSegmentKey = (options?: QueryOptions<SegmentKey, any>): QueryResult<SegmentKey> => useQuery(
  [SegmentQueryKeys.SegmentKey],
  async (): Promise<SegmentKey> => API.getSegmentKey.call(),
  options,
);
