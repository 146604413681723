import { CookieKeys, CookieService, TrackingPreferences } from './types';

let trackingPreferences: TrackingPreferences = {
  disableTracking: false, // Set false by default
};

export const getTrackingPreference = (cookies: CookieService) => {
  const rawCookieValue = cookies.get(CookieKeys.TACKING_PREFERENCES);

  if (rawCookieValue) {
    try {
      trackingPreferences = JSON.parse(rawCookieValue) as TrackingPreferences;
    } catch (e) {
      // Do nothing
    }
  }
  return trackingPreferences;
};
